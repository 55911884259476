/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React from 'react';
import { Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button } from '@nettoken/components';
import { noop } from '@nettoken/helpers';
import Helmet from 'Helmet';
import Styles from 'Modal/style.css';
import Styles2 from 'Sidebar/style.css';
import CancelIcon from '../../../ActionIcons/CancelIcon';
import LeftArrowIcon from '../../../ActionIcons/LeftArrowIcon';
import dogKey from '../../../../assets/images/dog_with_key.svg';

const Checkbox = ({ checked, name, onChange }) => (
  <div className={Styles2.radioWrapper}>
    <input
      checked={checked}
      className={Styles2.radioInput}
      id={name}
      onChange={onChange}
      name={name}
      // type="radio" would not be focusable with keyboard
      type="checkbox" />

    <label
      className={Styles2.radioLabel}
      htmlFor={name}>
      <div className={Styles2.radioBorder} />
    </label>
  </div>
);

const SidebarBackupContainer = ({
  loading,
  backup,
  onCancel,
  onClickDownload,
  onClickExplain,
  t,
  lastUpdated,
  termsAccepted,
  onChangeCheckbox,
  disabled,
  formattedDate,
  onBackHandler,
  stepNo,
}) => (
  <React.Fragment>
    <h1 aria-label="Download Backup Key" className={Styles.title1}>
      {stepNo === 2 ? <LeftArrowIcon onClickBackHandler={onBackHandler} /> : null}
      {stepNo === 2 ? 'PDF downloaded!' : 'Download Backup Key'}
      <CancelIcon onCancel={onCancel} />
    </h1>
    <div className={`${Styles.smallModal} ${Styles.smallHeightBackground}`}>
      <div className={Styles.form}>
        {stepNo != 2 ? (
          <div
            className={`${Styles.content} ${Styles._menu} ${Styles._scrollable}`}
          >
            <div className={Styles.paddingFixer}>
              <ul>
                <li className={`${Styles.exportSectionItem}`}>
                  <p className={Styles.radioTextInExport}>
                    <p>
                  Download and safely store the pdf with your unique backup key.
                  When you register a new Nettoken
                  phone app with your account's
                  registered phone number it will prompt the backup key
                  input and you can restore your account instantly.
                    </p>
                    <br />
                    <p>
                      {' '}
                      Your backup key is required in the event that you:<br/>
                      1. lose your phone <br/>
                      2. delete the Nettoken phone app<br/>
                      3. transfer your Nettoken to a new phone<br/>
                    </p>
                    {lastUpdated && lastUpdated !== null && (
                      <div>
                        <p className={`${Styles2.radioTextInExport} ${Styles2._danger}`}>Please note when you download a backup key it
                        will make your old copy invalid.</p>
                        <div style={{ position: 'relative' }}>
                          <span>I understand and will delete the old copy</span>
                          <Checkbox
                            checked={termsAccepted}
                            name={'downloadBackup'}
                            onChange={onChangeCheckbox} />
                        </div>
                      </div>
                    )}
                  </p>
                  <center>
                    <Button
                      className={Styles.backupButton}
                      icon={true}
                      loading={loading}
                      disabled={disabled}
                      onClick={onClickDownload}
                      value={t('views.backup.button')}
                    />
                    {lastUpdated && lastUpdated !== null &&
                      (
                        <p className={Styles.lastDownloadPadding}>
                          Last downloaded: <b>{formattedDate}</b>
                        </p>
                      )
                    }
                  </center>
                </li>
              </ul>
            </div>
          </div>
        ) : (
          <div
            className={`${Styles.content} ${Styles._menu} ${Styles._scrollable}`}
          >
            <div className={Styles.paddingFixer}>
              <center>
                <img src={dogKey} />
              </center>
              <ul>
                <li className={`${Styles.exportSectionItem}`}>
                  <p className={Styles.radioTextInExport}>
                    <p><b>Keep your backup key PDF in a safe place</b> that
                    you can reach in the event that you: </p>
                    <br />
                    <p>
                      1. lose your phone <br/>
                      2. delete the Nettoken phone app<br/>
                      3. transfer your Nettoken to a new phone<br/><br/>
                      Please note that when you download a new
                      backup key it will make your old copy invalid.
                    </p>
                  </p>
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
    <div
    className={`${Styles.actions} ${Styles._squeezedAltered} ${Styles.actionsFixed2} ${Styles.fixedButtons}`}
    >
      <Button
        className={`${Styles.button}`}
        onClick={onClickExplain}
        value='Backup key explained'
        type="submit"
      />
    </div>
  </React.Fragment>
);

SidebarBackupContainer.propTypes = {
  loading: PropTypes.bool,
  onCancel: PropTypes.func,
  onClickDownload: PropTypes.func,
  /** i18n translate method */
  t: PropTypes.func.isRequired,
};

export default SidebarBackupContainer;
