/*
 * Copyright (C) 2018-2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { Credential, Group, LABEL_UNSORTED_GROUP } from '@nettoken/models';
import { isImmutable, List, Map } from 'immutable';
import { convertGlobalAccountsIntoObject } from 'main/search';
import { isCredentialDuplicate, shouldUpadteCredential } from 'main/import/common';

export {
  getMicrosoftClientId,
  GOOGLE,
  MICROSOFT,
  NAMESPACE,
  LOGOUT,
} from './common';

/**
 * @param {string} domain
 * @param {array} predefined
 *
 * @returns {object} Undefined if not found.
 */

const findGlobalAccountFromReferrer = (domain, predefined) => {
  for (let i = 0; i < predefined.length; i += 1) {
    const account = predefined[i];
    // if the global account referrer domain matches our credential domain, we have the account
    if (account.referrer && account.referrer.domain === domain) {
      return account;
    }
  }
  return undefined;
};

/**
 * Converts a simple list of credentials into a format that can be injected
 * into the `<PreviewImport />` component.
 *
 * @param {object} args
 * @property {List} credentials
 * @property {List} [emptyGroups]
 * @property {array} results
 * @property {boolean} [skipResultsNoMembership] Skip domains that do not have a membership zone
 * (user cannot sign in anywhere on the website)
 * @property {function} t
 * @property {string} unsortedId
 *
 * @returns {object}
 * @property {object} appsTree
 * @property {object} groupsTree
 * @property {array} groups
 */
export const prepareImportedAccountsForRender = args => {
  let accountsTree = new Map();
  let appsTree = new Map();
  let groupsTree = new Map();
  // let addedDomains = new Map();
  let groups = new List();

  const loopGroup = (id, apps) => {
    if (groups.includes(id)) return;
    const label = id !== args.unsortedId ? id : args.t('views.dashboard.unsortedGroup');
    const group = new Group({ apps, id, label });
    groupsTree = groupsTree.set(id, group.getState());
    groups = groups.push(id);
  };

  const globalAccounts = convertGlobalAccountsIntoObject(args.results, 'domain');

  args.credentials.forEach(credential => {
    const domain = credential.get('domain');
    const account = globalAccounts[domain] || findGlobalAccountFromReferrer(domain, args.results);

    // We might not always get back response for requested domain.
    if (!account) return;

    // If we've already added an account for this domain, return
    // if (addedDomains.get(account.domain)) return;

    let id = credential.get('groupId') || account.groupnames;
    if (!id || id === LABEL_UNSORTED_GROUP) id = args.unsortedId;

    // Skip domains without membership zone. The special id is a hack fix
    // created by Charlotte to filter out bad results.
    if (args.skipResultsNoMembership && id === 'This may not be an account') return;

    // Create hash branch, e.g. "Utilities"
    if (!accountsTree.get(id)) {
      accountsTree = accountsTree.set(id, new List());
    }

    let result = new Credential({
      accountId: account.id,
      cookieUrl: account.cookieUrl,
      domain: account.domain,
      email: credential.get('email'),
      groupId: id,
      id: credential.get('id'),
      loginUrl: credential.get('loginUrl'),
      meta: credential.get('meta'),
      name: account.name,
      note: credential.get('note'),
      password: credential.get('password'),
      picture: account.picture,
      privacyUrl: account.privacyUrl,
      username: credential.get('username'),
      dashboardSpaceId: credential.get('dashboardSpaceId'),
    });

    const newPassword = credential.get('password');

    if (args.skipDuplicateCredential && isCredentialDuplicate(result, args.credentialsDomainMap, {
      comparePassword: newPassword !== '',
      lowerBounds: 0.80,
      allowExtraInfo: false,
    })) {
      return;
    }

    if (args.allowMerge) {
      result = shouldUpadteCredential(result, args.credentialsDomainMap);
    }

    const credentialId = result.get('id');
    accountsTree = accountsTree.set(id, accountsTree.get(id).push(credentialId));
    appsTree = appsTree.set(credentialId, result.getState());
    // addedDomains = addedDomains.set(account.domain, true);
  });

  accountsTree.entrySeq().forEach(([id, apps]) => loopGroup(id, apps));

  if (isImmutable(args.emptyGroups)) {
    args.emptyGroups.forEach(id => loopGroup(id, []));
  }

  // Sort groups alphabetically.
  let groupIds = groups.sort((a, b) => {
    const aName = a.toUpperCase();
    const bName = b.toUpperCase();
    if (aName < bName) return -1;
    if (aName > bName) return 1;
    return 0;
  }).toArray();

  // Put unsorted on top if it exists.
  const unsortedIndex = groupIds.indexOf(args.unsortedId);
  if (unsortedIndex !== -1) {
    groupIds = [
      args.unsortedId,
      ...groupIds.slice(0, unsortedIndex),
      ...groupIds.slice(unsortedIndex + 1),
    ];
  }

  return {
    apps: appsTree.toJS(),
    groups: groupsTree.toJS(),
    groupIds,
  };
};
