/* eslint-disable */
/*
 * Copyright (C) 2018-2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { MODAL_ADD_ACCOUNT_NAVLIST } from 'constants/modal';
import { MODAL_MOVE_ACCOUNTS } from 'constants/routes';
import { SEARCH1 as ID_SEARCH } from 'constants/ids';
import { SOURCE_WEB_APP } from 'constants/events';
import React from 'react';
import { List } from 'immutable';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import debounce from 'lodash.debounce';
import { Request } from 'utils/request';
import { eventTarget } from '@nettoken/helpers';
import { LABEL_UNSORTED_GROUP } from '@nettoken/models';
import { arrayItemSliceOrAppend } from '@nettoken/helpers';
import { Credential } from '@nettoken/models';
import {
  getWorkerPromise,
  CW_ENCRYPT_CREDENTIALS,
  CW_ENCRYPT_USING_MASTER_KEYPAIR,
  CW_ENCRYPT_WITH_EXTERNAL_KEY,
  CW_DECRYPT_USING_MASTER_KEYPAIR,
  CW_GENERATE_EPHEMERAL_KEY_PAIR,
  CW_EXTRACT_MASTER_KEYS,
} from '@nettoken/crypto-worker';
import { hideModal, showModal } from 'main/modal';
import { removeSharedByAccounts } from 'main/sharedAccounts';
import { sharedInvitation } from 'main/sharedAccounts';
import { deleteCredentials } from 'main/vault/credentials';
import { readGroup } from 'main/vault/groups/reduxState';
import { history } from 'reducers/store';
import withAsyncState from 'AsyncState';
import withOverlayAction from 'Overlay/withAction';
import Debouncer from 'utils/debouncer';
import $ from 'jquery';
import KeyPair from '@nettoken/crypto-worker/src/keypair';
import Container from './container';
import { MODAL_OFFLINE_MSG } from '../../../../constants/modal';
import { RxEditAccountProccessing, RXToasterShow } from '../../../../main/modal/reduxActions';
import { getApiErrorStatus } from '../../../../main/ui/reduxState';
import { getNewCredentials, getOneCredential } from '../../../../main/vault/credentials/reduxState';
import { addCredentialsEfficiently, moveCredentialToGroupId, updateCredential } from '../../../../main/vault/credentials';
import { editUserCredential, getUserData } from '../../../../main/user';
import {
  BULK_URL_CREDENTIAL, DOWNWARD, URL_CREDENTIAL, URL_CREDENTIALS_SHARED, URL_USERS,
} from '../../../../constants/endpoints';
import { RXGroupsCreate } from '../../../../main/vault/groups/reduxActions';
import { authenticateUserData } from '../../../../main/auth';
import { getOrCreateKeyPairForSharing, getOrCreateKeyPairForSharingMultiConditions } from '../../../../utils/misc';

const debouncedSearch = new Debouncer(params => params.search(), 200);
class ModalMoveAccounts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      apps: {},
      filteredApps: {},
      isLoading: false,
      isSubmitDisabled: true,
      isSubmitting: false,
      query: '',
      selected: new List(),
      selectedApps: new List(),
      step: 'select',
      dashboardDropdown: [],
      selectedDashboard: '',
      selectedDashboardId: null,
      searchQuery: '',
      selectedDashboardName: {},
      selectedDashboardCounter: 0,
      showDropdown: false,
      currentDashboard: '',
      dashboardName: '',
      cdid: '',
      secondDashboard: '',
      dashboardCounter: '',
    };

    this.debounce = debounce(() => this.search(this.state.query), 300);
    this.handleSearch = debounce(() => this.searchCallback(this.state.query), 200);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeSearch = this.handleChangeSearch.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.loadAccounts = this.loadAccounts.bind(this);
    this.togglePassword = this.togglePassword.bind(this);
    this.toggleSelected = this.toggleSelected.bind(this);
    this.onClickBack = this.onClickBack.bind(this);
    this.onChangeDashboard = this.onChangeDashboard.bind(this);
    this.openDropdown = this.openDropdown.bind(this);
    this.switchDashboards = this.switchDashboards.bind(this);
    this.loadComponentData = this.loadComponentData.bind(this);
  }

  componentDidMount() {
    this.props.addOverlayAction('modal', this.props.hide);
    this.checkHashString();
    this.loadComponentData();
  }

  loadComponentData() {
    const Dashboarddropdown = [];
    let selectedCounter = 0;
    Object.values(this.props.dashboardList).map(dashboard => {
      if (dashboard.id === this.props.secondDashboard) {
        selectedCounter = Object.values(this.props.credentials).filter(cred => {
          if (
            'dashboardSpaceId' in cred &&
            cred.dashboardSpaceId === dashboard.id
          ) {
            return true;
          }
          return false;
        }).length;
      }
      if (
        dashboard.id !== this.props.currentDashboard &&
        dashboard.id !== this.props.secondDashboard
      ) {
        const counter = Object.values(this.props.credentials).filter(cred => {
          if (
            'dashboardSpaceId' in cred &&
            cred.dashboardSpaceId === dashboard.id
          ) {
            if (
              this.props.cdid != null &&
              this.props.state.ui.dashboards[this.props.cdid].shared &&
              this.props.state.ui.dashboards[this.props.cdid]
                .usersSharedWith.length > 0 &&
              (('usersSharedWith' in cred &&
              cred.usersSharedWith.filter(x => !('sharedByDashboard' in x) || !x.sharedByDashboard).length > 0) ||
              (cred.sharedByUserName != false && cred.sharedByUserName != null))
            ) {
              return false;
            }
            return true;
          }
          return false;
        }).length;
        return Dashboarddropdown.push({
          name: dashboard.name,
          value: dashboard.id,
          counter,
          externalDashboard: dashboard.externalDashboard,
          usersSharedWith: dashboard.usersSharedWith,
        });
      }
      return false;
    });
    this.setState({
      dashboardDropdown: Dashboarddropdown,
      selectedDashboard: this.props.cdid === null ?
        Object.values(this.props.dashboardList)[0].name :
        this.props.userName,
      selectedDashboardId: this.props.secondDashboard,
      selectedDashboardCounter: this.props.secondDashboard !== null ?
        selectedCounter :
        this.props.totalCounter,
      currentDashboard: this.props.cdid,
      dashboardName: this.props.dashboardName,
      cdid: this.props.cdid,
      secondDashboard: this.props.secondDashboard,
      dashboardCounter: this.props.dashboardCounter,
    }, () => this.loadAccounts());
  }

  openDropdown() {
    this.setState({
      showDropdown: !this.state.showDropdown,
    });
  }

  onChangeDashboard(selectedDashboardId, selectedDashboardName, selectedDashboardCounter) {
    const Dashboarddropdown = [];
    if (
      this.props.userName.toLowerCase() !== selectedDashboardName.toLowerCase() &&
      this.state.cdid !== null
    ) {
      const totalCounter = Object.values(this.props.credentials).filter(cred => {
        if (
          cred.dashboardSpaceId != null ||
          (
            this.state.currentDashboard != null &&
            this.props.state.ui.dashboards[this.state.currentDashboard].shared &&
            this.props.state.ui.dashboards[this.state.currentDashboard]
              .usersSharedWith.length > 0 &&
            (('usersSharedWith' in cred &&
            cred.usersSharedWith.filter(x => !('sharedByDashboard' in x) || !x.sharedByDashboard).length > 0) ||
            (cred.sharedByUserName != false && cred.sharedByUserName != null))
          )
        ) {
          return false;
        }
        return true;
      }).length;
      Dashboarddropdown.push({
        name: this.props.userName,
        value: null,
        counter: totalCounter,
        externalDashboard: true,
      });
    }
    Object.values(this.props.dashboardList).map(dashboard => {
      if (
        selectedDashboardName.toLowerCase() !== dashboard.name.toLowerCase() &&
        this.state.currentDashboard !== dashboard.id
      ) {
        const counter = Object.values(this.props.credentials).filter(cred => {
          if (
            'dashboardSpaceId' in cred &&
            cred.dashboardSpaceId === dashboard.id
          ) {
            if (
              this.state.currentDashboard != null &&
              this.props.state.ui.dashboards[this.state.currentDashboard].shared &&
              this.props.state.ui.dashboards[this.state.currentDashboard]
                .usersSharedWith.length > 0 &&
              (('usersSharedWith' in cred &&
              cred.usersSharedWith.filter(x => !('sharedByDashboard' in x) || !x.sharedByDashboard).length > 0) ||
              (cred.sharedByUserName != false && cred.sharedByUserName != null))
            ) {
              return false;
            }
            return true;
          }
          return false;
        }).length;
        Dashboarddropdown.push({
          name: dashboard.name,
          value: dashboard.id,
          counter,
          externalDashboard: dashboard.externalDashboard,
          usersSharedWith: dashboard.usersSharedWith,
        });
      }
    });
    this.setState({
      dashboardDropdown: Dashboarddropdown,
      selectedDashboard: selectedDashboardName,
      selectedDashboardId,
      selectedDashboardCounter,
      showDropdown: false,
    });
    let query = this.state.searchQuery;
    let filterApps = {};
    if (query === '') {
      Object.keys(this.state.apps)
        .forEach(item => {
          if ('dashboardSpaceId' in this.state.apps[item] &&
          this.state.apps[item].dashboardSpaceId === selectedDashboardId) {
            if (
              this.state.currentDashboard != null &&
              this.props.state.ui.dashboards[this.state.currentDashboard].shared &&
              this.props.state.ui.dashboards[this.state.currentDashboard]
                .usersSharedWith.length > 0 &&
              (('usersSharedWith' in this.state.apps[item] &&
              this.state.apps[item].usersSharedWith.filter(x => !('sharedByDashboard' in x) || !x.sharedByDashboard).length > 0) ||
              (this.state.apps[item].sharedByUserName != false && this.state.apps[item].sharedByUserName != null))
            ) {
              return false;
            }
            filterApps = {
              ...filterApps,
              [item]: this.state.apps[item],
            };
          }
          return true;
        });
      this.setState({
        filteredApps: filterApps,
      });
    }
    else {
      const { apps } = this.state;
      query = query.toLowerCase();
      const filteredApps = Object.keys(apps)
        .filter(item => {
          if (
            'dashboardSpaceId' in this.state.apps[item] &&
              this.state.apps[item].dashboardSpaceId === selectedDashboardId
          ) {
            if (
              this.state.currentDashboard != null &&
                this.props.state.ui.dashboards[this.state.currentDashboard].shared &&
                this.props.state.ui.dashboards[this.state.currentDashboard]
                  .usersSharedWith.length > 0 &&
                (('usersSharedWith' in this.state.apps[item] &&
                this.state.apps[item].usersSharedWith.filter(x => !('sharedByDashboard' in x) || !x.sharedByDashboard).length > 0) ||
                (this.state.apps[item].sharedByUserName != false && this.state.apps[item].sharedByUserName != null))
            ) {
              return false;
            }
            return true;
          }
          return false;
        })
        .filter(key => apps[key].name.toLowerCase().startsWith(query))
        .reduce((obj, key) => {
          obj[key] = apps[key];
          return obj;
        }, {});
      this.setState({
        filteredApps,
      });
    }
  }

  checkHashString() {
    history.replace({ search: `?${MODAL_MOVE_ACCOUNTS}`, hash: '' });
  }

  handleChange(event) {
    const { value: query } = eventTarget(event);
    this.setState({ query }, this.handleSearch);
  }

  handleChangeSearch(event) {
    const query = '';
    this.setState({ query }, this.handleSearch);
  }

  clearSearch() {
    const input = $(`#${ID_SEARCH}`);
    if (input) input.focus();
    this.handleChangeSearch();
  }

  searchCallback(query) {
    if (query === '') {
      let filterApps = {};
      Object.keys(this.state.apps)
        .map(item => {
          if ('dashboardSpaceId' in this.state.apps[item] &&
          this.state.apps[item].dashboardSpaceId === this.state.selectedDashboardId) {
            filterApps = {
              ...filterApps,
              [item]: this.state.apps[item],
            };
          }
        });
      this.setState({
        filteredApps: filterApps,
        searchQuery: query,
      });
      return;
    }
    const { apps } = this.state;
    query = query.toLowerCase();
    const filteredApps = Object.keys(apps)
      .filter(item => 'dashboardSpaceId' in this.state.apps[item] &&
        this.state.apps[item].dashboardSpaceId === this.state.selectedDashboardId)
      .filter(key => apps[key].name.toLowerCase().includes(query.toLowerCase()) ||
        apps[key].groupId.toLowerCase().includes(query.toLowerCase()) ||
        apps[key].email.toLowerCase().includes(query.toLowerCase()))
      .reduce((obj, key) => {
        obj[key] = apps[key];
        return obj;
      }, {});

    this.setState({
      filteredApps,
      searchQuery: query,
    });
  }

  async handleSelectSubmit() {
    const {
      apps,
      selected: credentialIds,
      selected,
    } = this.state;
    let selectedDashboardName = {};
    const filteredApps = Object.keys(apps)
      .filter(key => selected.includes(apps[key].id))
      .reduce((obj, key) => {
        if (
          !('dashboardSpaceId' in apps[key]) ||
          apps[key].dashboardSpaceId === '' ||
          apps[key].dashboardSpaceId === null
        ) {
          if (!('home' in selectedDashboardName)) {
            selectedDashboardName = {
              ...selectedDashboardName,
              home: {
                name: this.props.userName,
                counter: this.props.totalCounter,
              },
            };
          }
        }
        if (
          'dashboardSpaceId' in apps[key] &&
          apps[key].dashboardSpaceId !== '' &&
          apps[key].dashboardSpaceId !== null &&
          !(apps[key].dashboardSpaceId in selectedDashboardName)
        ) {
          const counter = Object.values(this.props.credentials).filter(cred => {
            if (
              'dashboardSpaceId' in cred &&
              cred.dashboardSpaceId === apps[key].dashboardSpaceId
            ) {
              return true;
            }
            return false;
          }).length;
          selectedDashboardName = {
            ...selectedDashboardName,
            [apps[key].dashboardSpaceId]: {
              name: this.props.dashboardList[apps[key].dashboardSpaceId].name,
              counter,
            },
          };
        }
        obj[key] = apps[key];
        return obj;
      }, {});

    this.setState({
      apps: filteredApps,
      filteredApps,
      step: 'confirm',
      selectedDashboardName,
      isSubmitting: false,
    });
  }

  // async getOrCreateKeyPairForSharing(credential) {
  //   const keypair = new KeyPair();
  //   const {
  //     publicKey,
  //     secretKey,
  //   } = credential;
  //   const worker = await getWorkerPromise('crypto');
  //   if (credential.shared === true && publicKey && secretKey && publicKey != null && secretKey != null) {
  //     const [pKey, sKey] = await Promise.all([
  //       worker({
  //         event: CW_DECRYPT_USING_MASTER_KEYPAIR,
  //         message: publicKey,
  //       }),
  //       worker({
  //         event: CW_DECRYPT_USING_MASTER_KEYPAIR,
  //         message: secretKey,
  //       }),
  //     ])
  //       .then(values => values.map(v => Object.values(v)
  //         .join('')
  //         .replace('CW_DECRYPT_USING_MASTER_KEYPAIR', '')));

  //     keypair.set(pKey, sKey);
  //   }
  //   else {
  //     const newKeyPair = await worker({
  //       event: CW_GENERATE_EPHEMERAL_KEY_PAIR,
  //       format: 'base64',
  //     });
  //     keypair.set(newKeyPair.publicKey, newKeyPair.secretKey);
  //   }
  //   return keypair;
  // }

  prepareCredentials = ({
    id,
    note,
    groupId,
    accountId,
    name,
    username,
    email,
    password,
    loginUrl,
    phone,
    publicKey,
    secretKey,
    shared,
    sharedByDashboard,
    dashboardSpaceId,
    picture,
  }) => ({
    id,
    note,
    groupId,
    accountId,
    name,
    username,
    email,
    password,
    loginUrl,
    phone,
    publicKey,
    secretKey,
    shared,
    sharedByDashboard,
    dashboardSpaceId,
    picture,
  });

  async handleSharingDashboard(currentDashboard, apps, updateCredential) {
    const { dashboards } = this.props;
    if (
      currentDashboard &&
      'usersSharedWith' in dashboards[currentDashboard] &&
      dashboards[currentDashboard].usersSharedWith.length > 0
    ) {
      const { usersSharedWith } = dashboards[currentDashboard];
      const credentials = apps;
      const sharedCredentialDataArr = {};
      let sharedCredentialDataArrCount = 0;
      let credentialsKeys = {};
      // usersSharedWith.forEach(async (user, userIndex) => {
      let userIndex = 0;
      for (let user of usersSharedWith) {
        // const element = array[index];
        // Object.keys(credentials).forEach(async accountId => {
        for (let accountId in credentials) {
          const credential = credentials[accountId];
          // console.log('spec_Check_daatta', credentialsKeys);
          if (accountId in credentialsKeys) {
            credential.publicKey = credentialsKeys[accountId].publicKey;
            credential.secretKey = credentialsKeys[accountId].secretKey;
          }
          const {
            id: sharedWithUserId,
            publicKey: sharedWithUserPublicKey,
            responded: dashboardStatus,
          } = user;
          console.log('spec_Check_daatta', credential.publicKey, credential.secretKey);
          const {
            publicKey,
            secretKey,
          } = await getOrCreateKeyPairForSharing(credential, KeyPair);
          const worker = await getWorkerPromise('crypto');
          const [pKey, sKey] = await Promise.all([
            worker({
              event: CW_ENCRYPT_USING_MASTER_KEYPAIR,
              message: publicKey,
            }),
            worker({
              event: CW_ENCRYPT_USING_MASTER_KEYPAIR,
              message: secretKey,
            }),
          ])
            .then(values => values.map(v => Object.values(v)
              .join('')
              .replace('CW_ENCRYPT_USING_MASTER_KEYPAIR', '')));
          credential.publicKey = pKey;
          credential.secretKey = sKey;
          credentialsKeys = {
            ...credentialsKeys,
            [accountId]: {
              publicKey: pKey,
              secretKey: sKey,
            }
          }
          credential.shared = true;
          credential.sharedByDashboard = true;
          credential.dashboardSpaceId = currentDashboard;
          let id = accountId;
          credential.groupId = updateCredential.filter(xc => xc.id == id)[0].groupId;
          const { encrypted } = await worker({
            event: CW_ENCRYPT_CREDENTIALS,
            credentials: [credential],
          });
          if (!encrypted[0]) {
            throw new Error('Encrypted credentials unavailable');
          }
          const [{
            encrypted: encryptedPublicKey,
          }, {
            encrypted: encryptedSecretKey,
          }] = await Promise.all([
            worker({
              event: CW_ENCRYPT_WITH_EXTERNAL_KEY,
              message: publicKey,
              inputFormat: 'plain',
              outputFormat: 'base64',
              publicKey: sharedWithUserPublicKey,
            }),
            worker({
              event: CW_ENCRYPT_WITH_EXTERNAL_KEY,
              message: secretKey,
              inputFormat: 'plain',
              outputFormat: 'base64',
              publicKey: sharedWithUserPublicKey,
            }),
          ]);
          const SharedCredential = {
            credential: this.prepareCredentials(encrypted[0]),
            accountId: credential.accountId,
            secretKey: encryptedSecretKey,
            publicKey: encryptedPublicKey,
            sharedWithUserId,
            hasPendingInvites: !dashboardStatus,
          };
          // this.props.getSharedCredentials(credential.id);
          // this.props.updateCredential(credential.id, credential);
          console.log('spec_cjeck_ddd', sharedCredentialDataArr, SharedCredential);
          if (userIndex in sharedCredentialDataArr) {
            sharedCredentialDataArr[userIndex].push(SharedCredential);
          }
          else {
            sharedCredentialDataArr[userIndex] = [SharedCredential];
          }
          credentials[accountId] = credential;
          console.log('spec_cjeck_ddd2', sharedCredentialDataArr, SharedCredential);
          sharedCredentialDataArrCount += 1;
          if (sharedCredentialDataArrCount >= (
            Object.keys(credentials).length * usersSharedWith.length
          )) {
            // const finalPayload = [...sharedCredentialDataArr];
            // sharedCredentialDataArr = [];
            this.callApiRequest(sharedCredentialDataArr, 0, credentials);
          }
        };
        userIndex += 1;
      };
    }
    else {
      this.setState({ canSubmit: false, isSubmitting: false });
      this.props.hide();
    }
  }

  callApiRequest(finalPayload, index, credentialsData) {
    console.log('spec_Check_33', index, finalPayload);
    const req = new Request(URL_CREDENTIALS_SHARED);
    req.authorise()
      .post({ credentials: finalPayload[index] })
      .then(() => {
        console.log('spec_Check_331', index);
        finalPayload[index].map(data => {
          if (!data.credential.externalAccount) {
            // const newCredential = {
            //   ...credentials[data.credential.id],
            //   // data.credential,
            // }
            let credential = credentialsData[data.credential.id];
            // credential.publicKey = pKey;
            // credential.secretKey = sKey;
            // credential.shared = true;
            // credential.sharedByDashboard = true;
            // credential.dashboardSpaceId = dashboards[currentDashboard].id;
            // this.props.getSharedCredentials(credential.id);
            // this.props.updateCredential(credential.id, credential);
          }
        });
        if (index >= Object.keys(finalPayload).length - 1) {
          this.setState({ canSubmit: false, isSubmitting: false });
          this.props.hide();
        }
        else {
          this.callApiRequest(finalPayload, index + 1, credentialsData);
        }
      })
      .catch(e => console.log('shared error', e));
  }

  async handleMove() {
    const {
      apps,
      selected: credentialIds,
      selected,
    } = this.state;
    this.props.setEditAccountProccessing(true);
    const { dispatch, state } = this.props;
    const promises = credentialIds.map(id => {
      if (apps[id].externalAccount) {
        const putParams = {
          sharedId: apps[id].sharedId,
          dashboardSpaceId: this.state.currentDashboard,
          notify: false,
          setHomeDashboard:
            this.state.currentDashboard === '' ||
            this.state.currentDashboard === null ? 'true' : 'false',
        };
        const req1 = new Request(DOWNWARD);
        return new Promise((resolve, reject) => {
          req1.authorise().put(putParams)
            .then(res1 => {
              const account = {
                ...apps[id],
                groupId: res1.groupId,
                prevGroupId: this.props.credentials[id].groupId,
                dashboardSpaceId: res1.dashboardSpaceId,
                prevGroupName: this.props.groups[this.props.credentials[id].groupId].label,
              };
              resolve(account);
            });
        });
      }
      return false;
    });

    const SharedCredentials = [];
    const unSharedCredentials = [];
    const unSharedCredentialsUID = [];

    const { dashboards } = this.props.state.ui;
    // console.log('spec_CJCJC', this.state.currentDashboard, dashboards, dashboards[this.state.currentDashboard], this.props.state.ui);

    const { selectedDashboardName } = this.state;
    const fromDashboardsId = Object.keys(selectedDashboardName)[0];
    // console.log('spec_checkkk_dd', fromDashboardsId);
    if (fromDashboardsId &&
      fromDashboardsId != 'home' &&
      dashboards[fromDashboardsId].shared &&
      dashboards[fromDashboardsId].usersSharedWith
    ) {
      for (const id of credentialIds) {
        if (!apps[id].externalAccount) {
          const credential = {
            accountId: apps[id].accountId,
            id: id,
            name: apps[id].name,
            username: apps[id].username,
            email: apps[id].email,
            password: apps[id].password,
            note: apps[id].note,
            groupId: this.props.credentials[id].groupId,
            dashboardSpaceId: this.props.credentials[id].dashboardSpaceId,
            shared: false,
          };          
          const {
            publicKey,
            secretKey,
          } = await getOrCreateKeyPairForSharingMultiConditions(credential, KeyPair);
          const worker = await getWorkerPromise('crypto');
          const [pKey, sKey] = await Promise.all([
            worker({
              event: CW_ENCRYPT_USING_MASTER_KEYPAIR,
              message: publicKey,
            }),
            worker({
              event: CW_ENCRYPT_USING_MASTER_KEYPAIR,
              message: secretKey,
            }),
          ])
            .then(values => values.map(v => Object.values(v)
              .join('')
              .replace('CW_ENCRYPT_USING_MASTER_KEYPAIR', '')));
          credential.dashboardSpaceId = this.props.credentials[id].dashboardSpaceId;
          const { encrypted } = await worker({
            event: CW_ENCRYPT_CREDENTIALS,
            credentials: [credential],
          });         
          unSharedCredentials.push(encrypted[0]);
        }
      }
      if (unSharedCredentials.length > 0) {
        for (const user of dashboards[fromDashboardsId].usersSharedWith) {
          unSharedCredentialsUID.push(user.id);
        }
      }
    }
    // console.log('spec_checkkk_dd', unSharedCredentialsUID);
    let unSharedUpdatedAccounts = [];
    if (unSharedCredentials.length > 0) {
      try {
        const reqBulkUnShare = new Request(URL_CREDENTIALS_SHARED);
        await reqBulkUnShare.authorise().put({
          credential: unSharedCredentials,
          unshareWithUserIds: unSharedCredentialsUID,
        }).then(res => {
          unSharedUpdatedAccounts = res;
        });
      }
      catch (error) {
        console.log('Error:', error);
      }
    }

    const bulkApiData = await Promise.all(
      credentialIds.map(async id => {
        if (!apps[id].externalAccount) {
          const credential = new Credential({
            ...apps[id],
            groupId: this.props.credentials[id].groupId,
            dashboardSpaceId: this.state.currentDashboard,
          });
          let shared = credential.get('shared');
          let publicKey = credential.get('publicKey');
          let secretKey = credential.get('secretKey');
          if (unSharedUpdatedAccounts.length > 0) {
            let cred = unSharedUpdatedAccounts.filter(x => x.id == id);
            if (cred.length > 0 && !cred[0].shared) {
              shared = false;
              publicKey = null;
              secretKey = null;
            }
          }
          const worker = getWorkerPromise('crypto');
          const account = {
            accountId: credential.get('accountId'),
            loginUrl: credential.get('loginUrl'),
            email: credential.get('email'),
            groupId: credential.get('groupId'),
            note: credential.get('note'),
            name: credential.get('name'),
            password: credential.get('password'),
            phone: credential.get('phone'),
            username: credential.get('username'),
            shared,
            publicKey,
            secretKey,
            dashboardSpaceId: credential.get('dashboardSpaceId'),
            id: id,
          };
          const data = await worker({ event: CW_ENCRYPT_CREDENTIALS, credentials: [account] });
          const [encrypted] = data.encrypted;
          encrypted.dashboardSpaceId = account.dashboardSpaceId === '' ? null : account.dashboardSpaceId;
          encrypted.id = id;
          // const req = new Request(URL_CREDENTIAL);
          // const res = await req.setUrlParams({
          // id: credential.get('id')
          // }).authorise().put(encrypted);
          // res.credential.prevGroupId = account.groupId;
          // return res.credential;
          return encrypted;
        }
        return Promise.resolve(false);
      }),
    );

    let updateCredential = [];
    try {
      const reqBulk = new Request(BULK_URL_CREDENTIAL);
      await reqBulk.authorise()
        .put({ credentials_data: bulkApiData.filter(x => x != false) })
        .then((res) => {
          updateCredential = res.updated_credentials;
        });
    } catch (error) {
      console.log('Error:', error);
    }

    if (this.state.currentDashboard &&
      dashboards[this.state.currentDashboard].shared &&
      dashboards[this.state.currentDashboard].usersSharedWith
    ) {
      this.handleSharingDashboard(this.state.currentDashboard, apps, updateCredential);
      // for (const user of dashboards[this.state.currentDashboard].usersSharedWith) {
      //   for (const id of credentialIds) {
      //     if (!apps[id].externalAccount) {
      //       const credential = {
      //         ...apps[id],
      //         groupId: this.props.credentials[id].groupId,
      //         dashboardSpaceId: this.state.currentDashboard,
      //       };
      //       const {
      //         id: sharedWithUserId,
      //         publicKey: sharedWithUserPublicKey,
      //         responded: dashboardStatus,
      //       } = user;
      //       const {
      //         publicKey,
      //         secretKey,
      //       } = await getOrCreateKeyPairForSharingMultiConditions(credential, KeyPair);
      //       const worker = await getWorkerPromise('crypto');
      //       const [pKey, sKey] = await Promise.all([
      //         worker({
      //           event: CW_ENCRYPT_USING_MASTER_KEYPAIR,
      //           message: publicKey,
      //         }),
      //         worker({
      //           event: CW_ENCRYPT_USING_MASTER_KEYPAIR,
      //           message: secretKey,
      //         }),
      //       ])
      //         .then(values => values.map(v => Object.values(v)
      //           .join('')
      //           .replace('CW_ENCRYPT_USING_MASTER_KEYPAIR', '')));
      //       credential.publicKey = pKey;
      //       credential.secretKey = sKey;
      //       credential.shared = true;
      //       credential.sharedByDashboard = true;
      //       credential.dashboardSpaceId = this.state.currentDashboard;
      //       credential.groupId = updateCredential.filter(xc => xc.id == id)[0].groupId;
      //       const { encrypted } = await worker({
      //         event: CW_ENCRYPT_CREDENTIALS,
      //         credentials: [credential],
      //       });
      //       if (!encrypted[0]) {
      //         throw new Error('Encrypted credentials unavailable');
      //       }
      //       const [{
      //         encrypted: encryptedPublicKey,
      //       }, {
      //         encrypted: encryptedSecretKey,
      //       }] = await Promise.all([
      //         worker({
      //           event: CW_ENCRYPT_WITH_EXTERNAL_KEY,
      //           message: publicKey,
      //           inputFormat: 'plain',
      //           outputFormat: 'base64',
      //           publicKey: sharedWithUserPublicKey,
      //         }),
      //         worker({
      //           event: CW_ENCRYPT_WITH_EXTERNAL_KEY,
      //           message: secretKey,
      //           inputFormat: 'plain',
      //           outputFormat: 'base64',
      //           publicKey: sharedWithUserPublicKey,
      //         }),
      //       ]);
      //       const SharedCredential = {
      //         credential: this.prepareCredentials(encrypted[0]),
      //         accountId: id,
      //         secretKey: encryptedSecretKey,
      //         publicKey: encryptedPublicKey,
      //         sharedWithUserId,
      //         hasPendingInvites: !dashboardStatus,
      //       };
      //       SharedCredentials.push(SharedCredential);
      //     }
      //   }
      // }
    }

    // if (SharedCredentials.length > 0) {
    //   try {
    //     const reqBulkShare = new Request(URL_CREDENTIALS_SHARED);
    //     await reqBulkShare.authorise().post({ credentials: SharedCredentials });
    //   }
    //   catch (error) {
    //     console.log('Error:', error);
    //   }
    // }
    
    Promise.all(promises).then(async values => {
      // NEW CODE
      const { token, authenticate } = this.props;
      const { encryptedUserCredentials, profile } = await getUserData(token, true, true);

      const {
        groups, groupsOrder, id, shared,
      } = encryptedUserCredentials;
      await authenticate(
        id, token, groups, groupsOrder, shared, { override: true, skipPrompt: true }, profile,
      );
      if (!this.state.currentDashboard || !dashboards[this.state.currentDashboard].shared || !dashboards[this.state.currentDashboard].usersSharedWith) {
        this.setState({ canSubmit: false, isSubmitting: false });
        this.props.hide();
      }
    });
  }

  async handleSubmitCommon() {
    if (!navigator.onLine) {
      this.setState({ isSubmitting: true });
      this.props.setEditAccountProccessing(true);
      setTimeout(() => {
        this.setState({ isSubmitting: false });
        this.props.setEditAccountProccessing(false);
      }, 2000);
      this.props.showToaster({
        status: 'open',
        type: 'error',
        value: 'Oops, could not save! Please try again',
      });
      return;
    }
    this.props.addOverlayAction('modal', this.props.hide);
    if (!this.state.selected.size) return;
    this.setState({ isSubmitting: true });
    this.props.addOverlayAction('modal', () => {});
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.handleSubmitCommon();

    if (this.state.step === 'select') {
      this.handleSelectSubmit();
      return;
    }

    this.handleMove();
  }

  loadAccounts() {
    const tmpApps = Object.keys(this.props.credentials).reduce((obj, key) => {
      const { groupId } = this.props.credentials[key];
      let groupName;
      if (this.props.groups[groupId]) {
        groupName = this.props.groups[groupId].label;
      }
      const isUnsorted = groupName === LABEL_UNSORTED_GROUP;
      groupName = isUnsorted ? '' : (groupName || '');
      obj[key] = { ...this.props.credentials[key] };
      obj[key].groupId = groupName;
      obj[key].showPassword = false;
      return obj;
    }, {});

    let apps = {};
    let filteredApps = {};
    Object.values(tmpApps).map(obj => {
      let validate = true;
      if (
        obj.invitationId !== ''
      ) {
        validate = false;
      }
      else {
        apps = {
          ...apps,
          [obj.id]: obj,
        };
        if (this.state.currentDashboard === null) {
          if (
            ('dashboardSpaceId' in obj) &&
            obj.dashboardSpaceId === Object.keys(this.props.dashboardList)[0]
          ) {
            filteredApps = {
              ...filteredApps,
              [obj.id]: obj,
            };
          }
        }
        else if (
          (
            !('dashboardSpaceId' in obj) &&
            (
              this.state.currentDashboard === '' ||
              this.state.currentDashboard === null
            )
          ) ||
          (
            obj.dashboardSpaceId === '' ||
            obj.dashboardSpaceId === null
          )
        ) {
          if (
            this.state.currentDashboard != null &&
            this.props.state.ui.dashboards[this.state.currentDashboard].shared &&
            this.props.state.ui.dashboards[this.state.currentDashboard]
              .usersSharedWith.length > 0 &&
            (('usersSharedWith'in obj && 
            obj.usersSharedWith.filter(x => !('sharedByDashboard' in x) || !x.sharedByDashboard).length > 0) ||
            (obj.sharedByUserName != false && obj.sharedByUserName != null))
          ) {
            return false;
          }
          filteredApps = {
            ...filteredApps,
            [obj.id]: obj,
          };
        }
      }
      return validate;
    });

    this.setState({
      apps,
      filteredApps,
    });
  }

  // TODO very slow, needs perf improvements
  search(query) {
    if (!query) query = '';
    query = query.toUpperCase();
    let apps = [];
    Object.entries(this.props.credentials).forEach(([, app]) => {
      const displayLogin = app.email || app.username || app.phone;
      let match = !query;

      if (!match) {
        const matchesName = app.name.toUpperCase().startsWith(query);
        match = matchesName;
      }

      if (!match) {
        const matchesDisplayLogin = displayLogin.toUpperCase().startsWith(query);
        match = matchesDisplayLogin;
      }

      if (match) {
        let isChecked = false;
        if (this.state.selected.length) {
          this.state.selected.forEach(item => {
            if (app.id === item.id) {
              isChecked = true;
            }
          });
        }
        apps = [
          ...apps,
          Object.assign({ checked: isChecked, displayLogin }, app),
        ];
      }
    });

    apps = apps.sort((a, b) => {
      const aName = a.name ? a.name.toUpperCase() : '';
      const bName = b.name ? b.name.toUpperCase() : '';
      if (aName < bName) return -1;
      if (aName > bName) return 1;
      return 0;
    });

    this.setState({ apps });
  }

  setLoader(isLoading) {
    this.props.AsyncSetState(() => this.setState({ isLoading }));
  }

  setSubmitting(isSubmitting) {
    this.setState({ isSubmitting });
  }

  togglePassword(id) {
    this.setState({
      filteredApps: {
        ...this.state.filteredApps,
        [id]: {
          ...this.state.filteredApps[id],
          showPassword: !this.state.filteredApps[id].showPassword,
        },
      },
      apps: {
        ...this.state.apps,
        [id]: {
          ...this.state.apps[id],
          showPassword: !this.state.apps[id].showPassword,
        },
      },
    });
  }

  toggleSelected(id) {
    let { selected } = this.state;
    selected = arrayItemSliceOrAppend(selected, id);
    const isSubmitDisabled = selected.size === 0;
    this.setState({ isSubmitDisabled, selected });
  }

  onClickBack() {
    if (this.state.step == 'confirm') {
      this.setState({
        step: 'select',
        isSubmitDisabled: true,
        selected: new List(),
      });
      this.props.addOverlayAction('modal', this.props.hide);
      this.loadAccounts();
    }
    else if ('backBtn' in this.props.data) {
      this.props.data.backBtn();
    }
  }

  switchDashboards() {
    const { state } = this.props;
    const { selectedDashboardId } = this.state;
    let { cdid } = this.state;
    const newSelectedDashboardId = cdid;

    const dashboardName = !selectedDashboardId ? this.props.userName :
      this.props.dashboardList[selectedDashboardId].name;
    const newDashboardName = !newSelectedDashboardId ? this.props.userName :
      this.props.dashboardList[newSelectedDashboardId].name;
    cdid = selectedDashboardId;
    let secondDashboard = null;
    let dashboardCounter = 0;
    if (cdid === null) {
      secondDashboard = Object.values(state.ui.dashboards)[0].id;
    }
    dashboardCounter = Object.values(state.credentials.data).filter(cred => {
      if (
        (
          !('dashboardSpaceId' in cred) &&
          cdid === null
        ) ||
        (
          ('dashboardSpaceId' in cred) &&
          cred.dashboardSpaceId === cdid
        )
      ) {
        // if (
        //   cdid != null &&
        //   this.props.state.ui.dashboards[cdid].shared &&
        //   this.props.state.ui.dashboards[cdid]
        //     .usersSharedWith.length > 0 &&
        //   'usersSharedWith'in cred && 
        //   cred.usersSharedWith.length > 0
        // ) {
        //   return false;
        // }
        // else {
        return true;
        // }
      }
      return false;
    }).length;
    const newDashboardCounter = Object.values(state.credentials.data).filter(cred => {
      if (
        (
          !('dashboardSpaceId' in cred) &&
          newSelectedDashboardId === null
        ) ||
        (
          ('dashboardSpaceId' in cred) &&
          cred.dashboardSpaceId === newSelectedDashboardId
        )
      ) {
        if (
          cdid != null &&
          this.props.state.ui.dashboards[cdid].shared &&
          this.props.state.ui.dashboards[cdid]
            .usersSharedWith.length > 0 &&
          (('usersSharedWith'in cred && 
          cred.usersSharedWith.filter(x => !('sharedByDashboard' in x) || !x.sharedByDashboard).length > 0) ||
          (cred.sharedByUserName != false && cred.sharedByUserName != null))
        ) {
          return false;
        }
        else {
          return true;
        }
      }
      return false;
    }).length;
    const newDashboarddropdown = [];
    if (
      this.props.userName.toLowerCase() !== newDashboardName.toLowerCase() &&
      cdid !== null
    ) {
      newDashboarddropdown.push({
        name: this.props.userName,
        value: null,
        counter: this.props.totalCounter,
        externalDashboard: true
      });
    }
    Object.values(this.props.dashboardList).map(dashboard => {
      if (
        dashboardName.toLowerCase() !== dashboard.name.toLowerCase() &&
        cdid !== dashboard.id
      ) {
        const counter = Object.values(this.props.credentials).filter(cred => {
          if (
            'dashboardSpaceId' in cred &&
            cred.dashboardSpaceId === dashboard.id
          ) {
            if (
              cdid != null &&
              this.props.state.ui.dashboards[cdid].shared &&
              this.props.state.ui.dashboards[cdid]
                .usersSharedWith.length > 0 &&
              (('usersSharedWith'in cred && 
              cred.usersSharedWith.filter(x => !('sharedByDashboard' in x) || !x.sharedByDashboard).length > 0) ||
              (cred.sharedByUserName != false && cred.sharedByUserName != null))
            ) {
              return false;
            }
            else {
              return true;
            }
          }
          return false;
        }).length;
        newDashboarddropdown.push({
          name: dashboard.name,
          value: dashboard.id,
          counter,
          externalDashboard: dashboard.externalDashboard,
          usersSharedWith: dashboard.usersSharedWith,
        });
      }
    });
    this.setState({
      currentDashboard: cdid,
      dashboardName,
      cdid,
      secondDashboard,
      dashboardCounter,
      dashboardDropdown: newDashboarddropdown,
      selectedDashboard: newDashboardName,
      selectedDashboardId: newSelectedDashboardId,
      selectedDashboardCounter: newDashboardCounter,
      showDropdown: false,
    }, () => this.onChangeDashboard(newSelectedDashboardId, newDashboardName, newDashboardCounter));
  }

  render() {
    return (
      <Container
        apps={this.state.filteredApps}
        canSubmit={!this.state.isSubmitDisabled}
        isLoading={this.state.isLoading}
        isSubmitting={this.state.isSubmitting}
        onCancel={this.props.hide}
        onClickClearSearch={this.clearSearch}
        onChangeSearch={this.handleChange}
        onClickApp={this.toggleSelected}
        onClickPasswordToggle={this.togglePassword}
        onSubmit={this.handleSubmit}
        query={this.state.query}
        selected={this.state.selected}
        step={this.state.step}
        onClickBackHandler={this.onClickBack}
        t={this.props.t}
        dashboardName={this.state.dashboardName}
        dashboards={this.state.dashboardDropdown}
        onChangeDashboard={this.onChangeDashboard}
        selectedDashboard={this.state.selectedDashboard}
        selectedApps={this.state.selectedApps}
        selectedDashboardName={this.state.selectedDashboardName}
        userName={this.props.userName}
        selectedDashboardCounter={this.state.selectedDashboardCounter}
        alreadySharedDashboard={this.props.alreadySharedDashboard}
        sharedWithMeDashboard={this.props.sharedWithMeDashboard}
        dashboardCounter={this.state.dashboardCounter}
        openDropdown={this.openDropdown}
        showDropdown={this.state.showDropdown}
        switchDashboards={this.switchDashboards}
        dashboardList={this.props.dashboardList}
        selectedDashboardId={this.state.selectedDashboardId}
        currentDashboard={this.state.currentDashboard}
      />
    );
  }
}

const mapStateToProps = state => {
  let dashboardName = '';
  let cdid = null;
  let secondDashboard = null;
  let dashboardCounter = 0;
  if (
    state.ui.modalData !== null &&
    'id' in state.ui.modalData &&
    state.ui.modalData.id !== null &&
    'name' in state.ui.dashboards[state.ui.modalData.id]
  ) {
    dashboardName = state.ui.dashboards[state.ui.modalData.id].name;
    cdid = state.ui.modalData.id;
  }
  else if (
    (
      state.ui.currentDashboard === '' ||
      state.ui.currentDashboard === null
    ) &&
    (
      state.ui.modalData === null ||
      state.ui.modalData.id === null
    )
  ) {
    dashboardName = state.user.profile.name;
    cdid = null;
  }
  else if (
    (
      state.ui.currentDashboard !== '' ||
      state.ui.currentDashboard !== null
    ) &&
    (
      state.ui.modalData !== null &&
      'id' in state.ui.modalData &&
      state.ui.modalData.id === null
    )
  ) {
    dashboardName = state.user.profile.name;
    cdid = null;
  }
  else {
    dashboardName = state.ui.dashboards[state.ui.currentDashboard].name;
    cdid = state.ui.currentDashboard;
  }


  const totalCounter = Object.values(state.credentials.data).filter(cred => {
    if (
      (!('dashboardSpaceId' in cred) ||
      cred.dashboardSpaceId === '' ||
      cred.dashboardSpaceId === null) &&
      cred.invitationId === ''
    ) {
      if (
        cdid != null &&
        state.ui.dashboards[cdid].shared &&
        state.ui.dashboards[cdid]
          .usersSharedWith.length > 0 &&
        (('usersSharedWith'in cred && 
        cred.usersSharedWith.filter(x => !('sharedByDashboard' in x) || !x.sharedByDashboard).length > 0) ||
        (cred.sharedByUserName != false && cred.sharedByUserName != null))
      ) {
        return false;
      }
      return true;
    }
    return false;
  }).length;

  dashboardCounter = Object.values(state.credentials.data).filter(cred => {
    if (
      (
        !('dashboardSpaceId' in cred) &&
        cdid === null
      ) ||
      (
        ('dashboardSpaceId' in cred) &&
        cred.dashboardSpaceId === cdid
      )
    ) {
      return true;
    }
    return false;
  }).length;

  let dashboardList = {};
  Object.keys(state.ui.dashboards).map(dashboardId => {
    if (
      (!('externalDashboard' in state.ui.dashboards[dashboardId]) || (!state.ui.dashboards[dashboardId].externalDashboard)) && (!('invitationId' in state.ui.dashboards[dashboardId]))
    ) {
      dashboardList = {
        ...dashboardList,
        [dashboardId]: { ...state.ui.dashboards[dashboardId] },
      };
    }
  });

  if (cdid === null) {
    secondDashboard = Object.values(dashboardList)[0].id;
  }

  return {
    state,
    credentials: state.credentials.data,
    alreadySharedDashboard: state.ui.dashboards[state.ui.modalData.id] && 'shared' in state.ui.dashboards[state.ui.modalData.id] && state.ui.dashboards[state.ui.modalData.id].shared &&
    'usersSharedWith' in state.ui.dashboards[state.ui.modalData.id] && state.ui.dashboards[state.ui.modalData.id].usersSharedWith.length > 0,  
    sharedWithMeDashboard: state.ui.dashboards[state.ui.modalData.id] && 'externalDashboard' in state.ui.dashboards[state.ui.modalData.id] && state.ui.dashboards[state.ui.modalData.id].externalDashboard,    
    groups: state.groups.data,
    data: state.ui.modalData,
    editAccountProccessing: state.ui.editAccountProccessing,
    currentDashboard: cdid,
    userName: state.user.profile.name,
    dashboardList,
    dashboardName,
    cdid,
    secondDashboard,
    totalCounter,
    dashboardCounter,
    token: state.session.accessToken,
    dashboards: state.ui.dashboards,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
  deleteCredentials: args => dispatch(deleteCredentials(args)),
  removeSharedByAccounts: params => dispatch(removeSharedByAccounts(params)),
  sharedInvitation: params => dispatch(sharedInvitation(params)),
  hide: () => {
    // Remove the query string, otherwise the modal would keep opening.
    history.replace({ search: '' });
    dispatch(hideModal());
  },
  readGroup: id => dispatch(readGroup(id)),
  onClickBackHandler: args => dispatch(showModal(args)),
  showModal: (template, data) => dispatch(showModal(template, data)),
  showToaster: args => dispatch(RXToasterShow(args)),
  setEditAccountProccessing: status => dispatch(RxEditAccountProccessing(status)),
  getApiErrorStatus: () => dispatch(getApiErrorStatus()),
  getNewCredentials: () => dispatch(getNewCredentials()),
  addCredentialsEfficiently: args => dispatch(addCredentialsEfficiently(args)),
  updateCredential: args => dispatch(updateCredential(args)),
  getCredential: id => dispatch(getOneCredential(id)),
  editUserCredential: args => dispatch(editUserCredential(args)),
  authenticate: (...args) => dispatch(authenticateUserData(...args)),
});

export default translate()(connect(
  mapStateToProps,
  mapDispatchToProps,
)(withAsyncState(withOverlayAction(ModalMoveAccounts))));
