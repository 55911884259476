import React from 'react';
import Styles from 'Modal/style.css';
import classNames from 'classnames';
import { getIconColorNewmethod } from '../../utils/misc';

const UsersSharedWith = props => {
  const { usersSharedWith } = props;
  let styles = {
    display: '-webkit-inline-box', padding: '5px', paddingLeft: '0px', alignItems: 'center',
  };
  if ('customStyles' in props) {
    styles = {
      ...styles,
      ...props.customStyles,
    };
  }
  return (
    <div style={styles}>
      {usersSharedWith.slice(0, 1).map((x, index) => (
      <svg width='100%' height='100%' style={{ width: '32px', height: '32px', borderRadius: '100%' }}>
        <rect x='0' y='0' fill={getIconColorNewmethod(x.name)} height='100%' width='100%'></rect>
        <text
          dominantBaseline='middle'
          fill='#ffffff'
          fontSize='10'
          textAnchor='middle'
          x='48%'
          y='54%'
        >{x.name.slice(0, 2).toUpperCase()}</text>
      </svg>
      ))}
      {usersSharedWith.length > 1 ? (
        <div
          className={classNames({
            [Styles.circleUsersSharedWith]: true,
            [Styles.label]: true,
            [Styles.hideLabel]: true,
          })}
          style ={{ marginLeft: '-6px' }}
        >{'+'}{usersSharedWith.length - 1}</div>
      ) : null}
      {usersSharedWith.length > 0 && (
          <div style ={
            {
              marginLeft: '6px', color: '#868692', fontSize: '14px', marginTop: '5px',
            }
          }>
            In shared dashboard with {usersSharedWith[0].name.toString()}
            {usersSharedWith.length > 1 && ` and ${usersSharedWith.length - 1}`}
            {usersSharedWith.length == 2 ? ' other' : null}
            {usersSharedWith.length > 2 ? ' others' : null}
          </div>
      )}
    </div>
  );
};

export default UsersSharedWith;
