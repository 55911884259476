/* eslint-disable */
/*
 * Copyright (C) 2018-2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { VALID_URL as ID_VALID_URL, TUTORIAL_VALID_URL } from 'constants/ids';
import { MODAL_ADD_ACCOUNT_NAVLIST, MODAL_ACCOUNT_ADD } from 'constants/modal';
import { MODAL_ADD_ACCOUNT } from 'constants/routes';
import { SOURCE_ADVANCED_SETTINGS } from 'constants/events';
import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { List } from 'immutable';
import { eventTarget, findNode } from '@nettoken/helpers';
import { Credential } from '@nettoken/models';
import Validator from '@nettoken/validator';
import debounce from 'lodash.debounce';
import $ from 'jquery';
import { hideUIError, showUIError } from 'main/error';
import { getError } from 'main/error/reduxState';
import { hideModal, showModal } from 'main/modal';
import { hideTutorial, showTutorial } from 'main/tutorial';
import { getPredefinedAccounts } from 'main/search';
import { addCredentialsEfficiently } from 'main/vault/credentials';
import { getAllGroupDropdown } from 'main/vault/groups/reduxState';
import { history } from 'reducers/store';
import withOverlayAction from 'Overlay/withAction';
import KeyPair from '@nettoken/crypto-worker/src/keypair';
import { getSharedCredentialData } from 'main/sharedAccounts';
import { Request } from 'utils/request';
import {
  CW_DECRYPT_USING_MASTER_KEYPAIR,
  CW_ENCRYPT_CREDENTIALS,
  CW_ENCRYPT_USING_MASTER_KEYPAIR,
  CW_ENCRYPT_WITH_EXTERNAL_KEY,
  CW_GENERATE_EPHEMERAL_KEY_PAIR,
  getWorkerPromise,
} from '@nettoken/crypto-worker';
import { getApiErrorStatus } from '../../../../main/ui/reduxState';
import Container from './container';
import { getOrCreateAccounts, isReachable } from '../../../../main/search';
import { getNewCredentials } from '../../../../main/vault/credentials/reduxState';
import { URL_CREDENTIALS_SHARED } from '../../../../constants/endpoints';
import { RXCredentialsUpdateOne } from '../../../../main/vault/credentials/reduxActions';
import { getOrCreateKeyPairForSharing } from '../../../../utils/misc';


class ModalAdvancedSettingsComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      group: '',
      icon: '',
      loginUrl: '',
      name: '',
      note: '',
      password: '',
      showPassword: false,
      submitting: false,
      username: '',
      validLoginUrl: false,
      validatingURL: false,
      correctLoginUrl: '',
      accountId: '',
    };

    this.hasHint = false;
    this.timerIcon = null;
    this.debounced = null;

    this.hide = this.hide.bind(this);
    this.hidehint = this.hidehint.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.showHint = this.showHint.bind(this);
    this.togglePassword = this.togglePassword.bind(this);
    this.validURL = this.validURL.bind(this);
    this.validateUrl = this.validateUrl.bind(this);
    this.handleSharingDashboard = this.handleSharingDashboard.bind(this);
    // this.getOrCreateKeyPairForSharing = this.getOrCreateKeyPairForSharing.bind(this);
    this.prepareCredentials = this.prepareCredentials.bind(this);
    this.callApiRequest = this.callApiRequest.bind(this);
  }

  componentDidMount() {
    this.setCorrectUrl(true);
    this.props.addOverlayAction('modal', this.hide, false);
    this.showHint();
    if (!this.state.loginUrl) {
      this.props.showUIError('You cant leave this empty', 'loginUrl');
    }
  }

  componentWillUnmount() {
    this.setCorrectUrl(false);
    this.props.hideUIError();
    this.hidehint();
  }

  async fetchIcon(q) {
    try {
      const [res] = await getPredefinedAccounts({ limit: 1, q, skip: 0 });
      if (res && res.picture) {
        this.setState({ icon: res.picture });
      }
      else {
        this.setState({ icon: '' });
      }
    }
    catch (e) {
      console.log(e);
    }
  }

  submitting(submitting) {
    this.setState({ submitting });
  }

  hide() {
    if (!this.state.submitting) {
      this.setCorrectUrl(false);
      this.hidehint();
      this.props.hide();
    }
  }

  hidehint(notCompleted = false) {
    if (!this.hasHint) return;
    this.props.hideTutorial(TUTORIAL_VALID_URL, notCompleted);
    this.hasHint = false;
  }

  validURL(str) {
    const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
  }

  async validateUrl(value, name) {
    const q = value;
    let isUrlCorrect = true;
    const [res] = await getPredefinedAccounts({ limit: 1, q, skip: 0 });
    if (res == null) {
      isUrlCorrect = await isReachable({ url: value });
    }

    if (isUrlCorrect) {
      const accounts = await getOrCreateAccounts([value], false);
      if (accounts.length === 0) {
        this.props.showUIError('Must be a valid url', name);
        clearTimeout(this.timerIcon);
        this.setState({
          icon: '',
          validLoginUrl: false,
          validatingURL: false,
          correctLoginUrl: '',
          accountId: '',
        });
      }
      else {
        this.props.hideUIError(name);
        clearTimeout(this.timerIcon);
        this.setState({
          validLoginUrl: true,
          validatingURL: false,
          correctLoginUrl: accounts[0].loginUrl,
          accountId: accounts[0].id,
          group: accounts[0].groupnames,
        });
        this.setState({ icon: accounts[0].picture });
      }
    }
    else {
      this.props.showUIError('Must be a valid url', name);
      clearTimeout(this.timerIcon);
      this.setState({
        icon: '',
        validLoginUrl: false,
        validatingURL: false,
        correctLoginUrl: '',
        accountId: '',
      });
    }
  }

  onChange(event) {
    const { name, value } = eventTarget(event);
    this.setState({ [name]: value }, () => {
      switch (name) {
        case 'loginUrl':
          if (this.validURL(value)) {
            this.setState({
              validatingURL: true,
            });
            if (this.debounced) this.debounced.cancel();
            this.debounced = debounce(() => this.validateUrl(value, name), 200);
            this.debounced();
          }
          else {
            this.props.showUIError('Must be a valid url', name);
            this.setState({ icon: '' });
          }
          break;

        case 'email':
          if (Validator.email(value) || this.state.email === '') {
            this.props.hideUIError(name);
          }
          else {
            this.props.showUIError('Must be a valid email', name);
          }
          break;

        default:
          break;
      }
    });
  }

  // async getOrCreateKeyPairForSharing(credential) {
  //   const keypair = new KeyPair();
  //   const {
  //     publicKey,
  //     secretKey,
  //   } = credential;
  //   const worker = await getWorkerPromise('crypto');
  //   if (publicKey && secretKey) {
  //     const [pKey, sKey] = await Promise.all([
  //       worker({
  //         event: CW_DECRYPT_USING_MASTER_KEYPAIR,
  //         message: publicKey,
  //       }),
  //       worker({
  //         event: CW_DECRYPT_USING_MASTER_KEYPAIR,
  //         message: secretKey,
  //       }),
  //     ])
  //       .then(values => values.map(v => Object.values(v)
  //         .join('')
  //         .replace('CW_DECRYPT_USING_MASTER_KEYPAIR', '')));

  //     keypair.set(pKey, sKey);
  //   }
  //   else {
  //     const newKeyPair = await worker({
  //       event: CW_GENERATE_EPHEMERAL_KEY_PAIR,
  //       format: 'base64',
  //     });
  //     keypair.set(newKeyPair.publicKey, newKeyPair.secretKey);
  //   }
  //   return keypair;
  // }

  prepareCredentials = ({
    id,
    note,
    groupId,
    accountId,
    name,
    username,
    email,
    password,
    loginUrl,
    phone,
    publicKey,
    secretKey,
    shared,
    sharedByDashboard,
    dashboardSpaceId,
    picture,
  }) => ({
    id,
    note,
    groupId,
    accountId,
    name,
    username,
    email,
    password,
    loginUrl,
    phone,
    publicKey,
    secretKey,
    shared,
    sharedByDashboard,
    dashboardSpaceId,
    picture,
  });

  async handleSharingDashboard() {
    const { dashboards, currentDashboard } = this.props.ui;
    if (
      currentDashboard &&
      'usersSharedWith' in dashboards[currentDashboard] &&
      dashboards[currentDashboard].usersSharedWith.length > 0
    ) {
      const { usersSharedWith } = dashboards[currentDashboard];
      const credentials = this.props.getNewCredentials();
      const sharedCredentialDataArr = {};
      let sharedCredentialDataArrCount = 0;
      let credentialsKeys = {};
      // usersSharedWith.forEach(async (user, userIndex) => {
      let userIndex = 0;
      for (let user of usersSharedWith) {
        // const element = array[index];
        // Object.keys(credentials).forEach(async accountId => {
        for (let accountId in credentials) {
          const credential = credentials[accountId];
          // console.log('spec_Check_daatta', credentialsKeys);
          if (accountId in credentialsKeys) {
            credential.publicKey = credentialsKeys[accountId].publicKey;
            credential.secretKey = credentialsKeys[accountId].secretKey;
          }
          const {
            id: sharedWithUserId,
            publicKey: sharedWithUserPublicKey,
            responded: dashboardStatus,
          } = user;
          const {
            publicKey,
            secretKey,
          } = await getOrCreateKeyPairForSharing(credential, KeyPair);
          const worker = await getWorkerPromise('crypto');

          const [pKey, sKey] = await Promise.all([
            worker({
              event: CW_ENCRYPT_USING_MASTER_KEYPAIR,
              message: publicKey,
            }),
            worker({
              event: CW_ENCRYPT_USING_MASTER_KEYPAIR,
              message: secretKey,
            }),
          ])
            .then(values => values.map(v => Object.values(v)
              .join('')
              .replace('CW_ENCRYPT_USING_MASTER_KEYPAIR', '')));
          credential.publicKey = pKey;
          credential.secretKey = sKey;
          credentialsKeys = {
            ...credentialsKeys,
            [accountId]: {
              publicKey: pKey,
              secretKey: sKey,
            },
          };
          credential.shared = true;
          credential.sharedByDashboard = true;
          credential.dashboardSpaceId = dashboards[currentDashboard].id;
          const { encrypted } = await worker({
            event: CW_ENCRYPT_CREDENTIALS,
            credentials: [credential],
          });
          if (!encrypted[0]) {
            throw new Error('Encrypted credentials unavailable');
          }
          const [{
            encrypted: encryptedPublicKey,
          }, {
            encrypted: encryptedSecretKey,
          }] = await Promise.all([
            worker({
              event: CW_ENCRYPT_WITH_EXTERNAL_KEY,
              message: publicKey,
              inputFormat: 'plain',
              outputFormat: 'base64',
              publicKey: sharedWithUserPublicKey,
            }),
            worker({
              event: CW_ENCRYPT_WITH_EXTERNAL_KEY,
              message: secretKey,
              inputFormat: 'plain',
              outputFormat: 'base64',
              publicKey: sharedWithUserPublicKey,
            }),
          ]);
          const SharedCredential = {
            credential: this.prepareCredentials(encrypted[0]),
            accountId,
            secretKey: encryptedSecretKey,
            publicKey: encryptedPublicKey,
            sharedWithUserId,
            hasPendingInvites: !dashboardStatus,
          };
          const req = new Request(URL_CREDENTIALS_SHARED);
          // this.props.getSharedCredentials(credential.id);
          // this.props.updateCredential(credential.id, credential);
          console.log('spec_cjeck_ddd', sharedCredentialDataArr, SharedCredential);
          if (userIndex in sharedCredentialDataArr) {
            sharedCredentialDataArr[userIndex].push(SharedCredential);
          }
          else {
            sharedCredentialDataArr[userIndex] = [SharedCredential];
          }
          credentials[accountId] = credential;
          console.log('spec_cjeck_ddd2', sharedCredentialDataArr, SharedCredential);
          sharedCredentialDataArrCount += 1;
          if (sharedCredentialDataArrCount >= (
            Object.keys(credentials).length * usersSharedWith.length
          )) {
            // const finalPayload = [...sharedCredentialDataArr];
            // sharedCredentialDataArr = [];
            this.callApiRequest(sharedCredentialDataArr, 0, credentials);
          }
        }
        userIndex += 1;
      }
    }
    else {
      this.submitting(false);
      this.hide();
    }
  }

  callApiRequest(finalPayload, index, credentialsData) {
    const req = new Request(URL_CREDENTIALS_SHARED);
    req.authorise()
      .post({ credentials: finalPayload[index] })
      .then(() => {
        finalPayload[index].map(data => {
          if (!data.credential.externalAccount) {
            let credential = credentialsData[data.credential.id];
            this.props.getSharedCredentials(credential.id);
            this.props.updateCredential(credential.id, credential);
          }
        });
        if (index >= Object.keys(finalPayload).length - 1) {
          this.submitting(false);
          this.hide();
        }
        else {
          this.callApiRequest(finalPayload, index + 1, credentialsData);
        }
      })
      .catch(e => console.log('shared error', e));
  }

  async onSubmit(event) {
    event.preventDefault();
    this.props.hideUIError();
    const credential = new Credential({
      name: this.state.name,
      email: this.state.email,
      loginUrl: this.state.correctLoginUrl,
      note: this.state.note,
      password: this.state.password,
      username: this.state.username,
      groupId: this.state.group,
    });
    const id = credential.get('id');
    const predefinedAccount = await getPredefinedAccounts({ ids: [this.state.accountId] });
    credential.set('loginUrl', predefinedAccount[0].loginUrl ? predefinedAccount[0].loginUrl : predefinedAccount[0].domain);
    this.submitting(true);
    this.props.addCredentialsEfficiently({
      credentialIds: new List([id]),
      credentials: { [id]: credential },
      eventSource: SOURCE_ADVANCED_SETTINGS,
      // group: this.state.group,
      loader: loading => {},
    })
      .then(() => {
        setTimeout(() => {
          if (this.props.getApiErrorStatus()) {
            this.submitting(false);
          }
          else {
            this.handleSharingDashboard();
          }
        }, 1000);
        this.submitting(false);
        this.hide();
      })
      .catch(() => this.submitting(false));
  }

  setCorrectUrl(mounted) {
    const search = mounted ? `?${MODAL_ADD_ACCOUNT}=form` : '';
    history.replace({ hash: '', search });
  }

  async showHint() {
    if (this.hasHint) return;

    const id = ID_VALID_URL;
    await findNode(`#${id}`);
    this.props.showTutorial({
      coords: {
        left: '-320px',
        top: '120px',
      },
      direction: 'right',
      placement: 'center',
      type: 'advanced',
      id,
      light: true,
      isFixed: false,
      name: TUTORIAL_VALID_URL,
      onConfirm: this.hidehint,
      text: TUTORIAL_VALID_URL,
      zIndex: 999,
      buttonText: 'Okay',
    });
    this.hasHint = true;
  }

  togglePassword() {
    this.setState({ showPassword: !this.state.showPassword });
  }

  render() {
    return (
      <Container
        app={this.state.name}
        getError={this.props.getError}
        group={this.state.group}
        groupDropdown={this.props.getGroupDropdown(this.state.group)}
        icon={this.state.icon}
        isPasswordVisible={this.state.showPassword}
        isSubmitDisabled={!this.state.validLoginUrl}
        loginUrl={this.state.loginUrl}
        note={this.state.note}
        onCancel={this.hide}
        onChange={this.onChange}
        onClickPasswordToggle={this.togglePassword}
        onSubmit={this.onSubmit}
        password={this.state.password}
        submitting={this.state.submitting}
        t={this.props.t}
        onClickBackHandler={this.props.onClickBackHandler}
        username={this.state.username}
        validatingURL={this.state.validatingURL} />
    );
  }
}

const mapStateToProps = state => ({
  // Needed to re-render when UI error changes.
  ui: state.ui,
});

const mapDispatchToProps = dispatch => ({
  addCredentialsEfficiently: opts => dispatch(addCredentialsEfficiently(opts)),
  getError: id => dispatch(getError(id)),
  getGroupDropdown: name => dispatch(getAllGroupDropdown(name)),
  hide: () => dispatch(hideModal()),
  hideModal: () => {
    // Remove the query string, otherwise the modal would keep opening.
    history.replace({ search: '' });
    dispatch(hideModal());
  },
  hideUIError: name => dispatch(hideUIError(name)),
  showUIError: (message, forElement) => dispatch(showUIError(message, forElement)),
  getApiErrorStatus: () => dispatch(getApiErrorStatus()),
  hideTutorial: name => dispatch(hideTutorial(name)),
  showTutorial: args => dispatch(showTutorial(args)),
  onClickBackHandler: () => dispatch(showModal(MODAL_ADD_ACCOUNT_NAVLIST)),
  getNewCredentials: () => dispatch(getNewCredentials()),
  getSharedCredentials: id => dispatch(getSharedCredentialData(id)),
  updateCredential: (id, data) => dispatch(RXCredentialsUpdateOne(id, data)),  
});

export default translate()(connect(
  mapStateToProps,
  mapDispatchToProps,
)(withOverlayAction(ModalAdvancedSettingsComponent)));
