/*
 * Copyright (C) 2018-2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { URL_CREDENTIALS_SHARED } from 'constants/endpoints';
import { MODAL_ACCOUNT_EDIT } from 'constants/modal';
import { hideModal, showModal } from 'main/modal';
import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { getSharedCredentialData } from 'main/sharedAccounts';
import { deleteCredentials } from 'main/vault/credentials';
import { Request } from 'utils/request';
import withOverlayAction from 'Overlay/withAction';
import { RXCredentialsUpdateOne } from 'main/vault/credentials/reduxActions';
import {
  CW_DECRYPT_USING_MASTER_KEYPAIR,
  CW_ENCRYPT_CREDENTIALS,
  CW_ENCRYPT_USING_MASTER_KEYPAIR,
  CW_ENCRYPT_WITH_EXTERNAL_KEY,
  CW_GENERATE_EPHEMERAL_KEY_PAIR,
  getWorkerPromise,
} from '@nettoken/crypto-worker';
import KeyPair from '@nettoken/crypto-worker/src/keypair';
import Container from './container';
import { MODAL_ACCOUNT_SHARE } from '../../../../constants/modal';
import { RXSharedAccountsCreate } from '../../../../main/sharedAccounts/reduxActions';
import { getOrCreateKeyPairForSharing } from '../../../../utils/misc';

class ModalAccountuserContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchUser: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlewindow = this.handlewindow.bind(this);
    // this.getOrCreateKeyPairForSharing = this.getOrCreateKeyPairForSharing.bind(this);
    this.prepareCredentials = this.prepareCredentials.bind(this);
    this.hide = this.hide.bind(this);
    this.back = this.back.bind(this);
  }

  componentDidMount() {
    this.props.addOverlayAction('modal', this.hide, false);
  }

  // async getOrCreateKeyPairForSharing(credential) {
  //   const keypair = new KeyPair();
  //   const {
  //     publicKey,
  //     secretKey,
  //   } = credential;
  //   const worker = await getWorkerPromise('crypto');
  //   if (publicKey && secretKey) {
  //     const [pKey, sKey] = await Promise.all([
  //       worker({
  //         event: CW_DECRYPT_USING_MASTER_KEYPAIR,
  //         message: publicKey,
  //       }),
  //       worker({
  //         event: CW_DECRYPT_USING_MASTER_KEYPAIR,
  //         message: secretKey,
  //       }),
  //     ])
  //       .then(values => values.map(v => Object.values(v)
  //         .join('')
  //         .replace('CW_DECRYPT_USING_MASTER_KEYPAIR', '')));

  //     keypair.set(pKey, sKey);
  //   }
  //   else {
  //     const newKeyPair = await worker({
  //       event: CW_GENERATE_EPHEMERAL_KEY_PAIR,
  //       format: 'base64',
  //     });
  //     keypair.set(newKeyPair.publicKey, newKeyPair.secretKey);
  //   }
  //   return keypair;
  // }

  prepareCredentials = ({
    id,
    note,
    groupId,
    accountId,
    name,
    username,
    email,
    password,
    loginUrl,
    phone,
    publicKey,
    secretKey,
    shared,
    picture,
  }) => ({
    id,
    note,
    groupId,
    accountId,
    name,
    username,
    email,
    password,
    loginUrl,
    phone,
    publicKey,
    secretKey,
    shared,
    picture,
  });

  async handleSubmit(event) {
    event.preventDefault();
    const credential = this.props.data.d;
    const {
      id: sharedWithUserId,
      publicKey: sharedWithUserPublicKey,
    } = this.props.data.e;
    const { accountId } = this.props.data.d;
    const {
      publicKey,
      secretKey,
    } = await getOrCreateKeyPairForSharing(credential, KeyPair);

    const worker = await getWorkerPromise('crypto');

    const [pKey, sKey] = await Promise.all([
      worker({
        event: CW_ENCRYPT_USING_MASTER_KEYPAIR,
        message: publicKey,
      }),
      worker({
        event: CW_ENCRYPT_USING_MASTER_KEYPAIR,
        message: secretKey,
      }),
    ])
      .then(values => values.map(v => Object.values(v)
        .join('')
        .replace('CW_ENCRYPT_USING_MASTER_KEYPAIR', '')));

    credential.publicKey = pKey;
    credential.secretKey = sKey;
    credential.shared = true;
    credential.hasPendingInvites = true;

    const { encrypted } = await worker({
      event: CW_ENCRYPT_CREDENTIALS,
      credentials: [credential],
    });
    if (!encrypted[0]) {
      throw new Error('Encrypted credentials unavailable');
    }

    const [{
      encrypted: encryptedPublicKey,
    }, {
      encrypted: encryptedSecretKey,
    }] = await Promise.all([
      worker({
        event: CW_ENCRYPT_WITH_EXTERNAL_KEY,
        message: publicKey,
        inputFormat: 'plain',
        outputFormat: 'base64',
        publicKey: sharedWithUserPublicKey,
      }),
      worker({
        event: CW_ENCRYPT_WITH_EXTERNAL_KEY,
        message: secretKey,
        inputFormat: 'plain',
        outputFormat: 'base64',
        publicKey: sharedWithUserPublicKey,
      }),
    ]);

    const SharedCredential = {
      credential: this.prepareCredentials(encrypted[0]),
      accountId,
      secretKey: encryptedSecretKey,
      publicKey: encryptedPublicKey,
      sharedWithUserId,
    };

    const req = new Request(URL_CREDENTIALS_SHARED);
    req.authorise()
      .post(SharedCredential)
      .then(data => {
        if (!credential.externalAccount) {
          // this.props.getSharedCredentials(credential.id);
          this.props.updateSharedAccounts(data.id, data.usersSharedWith);
          this.props.updateCredential(credential.id, {
            ...credential,
            usersSharedWith: data.usersSharedWith,
          });
        }

        return this.props.showModal(MODAL_ACCOUNT_EDIT, {
          ...this.props.data.d,
          shared: true,
        });
      })
      .catch(e => console.log('shared error', e));
  }


  handlewindow(e) {
  }

  hide() {
    this.props.hideModal();
  }

  back() {
    this.props.showModal(MODAL_ACCOUNT_SHARE, this.props.data.d);
  }


  render() {
    let t = this.props.data.d;
    let k = this.props.data.e;
    const { sharedAccounts } = this.props;
    if (this.props.data.d === undefined) {
      t = this.props.data;
      k = this.props.data;
    }

    let displayName;

    if (t.email && t.email.length > 0) {
      displayName = t.email;
    }
    else if (t.username && t.username.length > 0) {
      displayName = t.username;
    }
    else {
      displayName = t.name;
    }

    return (
      <Container
        name={t.name}
        displayName={displayName}
        email={t.email}
        userName={t.username}
        loginUrl={t.loginUrl}
        onSubmit={this.handleSubmit}
        onCancel={this.hide}
        onBack={this.back}
        picture={t.picture}
        searchuser={this.state.searchUser}
        userWindow={this.handlewindow}
        sharedCredentialData={k}
        domain={t.domain}
        accountShared={sharedAccounts[t.id] && sharedAccounts[t.id].filter(x => !('sharedByDashboard' in x) || !x.sharedByDashboard).length > 0}
        t={this.props.t}/>
    );
  }
}

const mapStateToProps = state => ({
  data: state.ui.modalData || {},
  sharedAccounts: state.sharedAccounts,
});


const mapDispatchToProps = dispatch => ({
  deleteCredentials: args => dispatch(deleteCredentials(args)),
  getSharedCredentials: id => dispatch(getSharedCredentialData(id)),
  updateCredential: (id, data) => dispatch(RXCredentialsUpdateOne(id, data)),
  showModal: (name, data) => dispatch(showModal(name, data)),
  hideModal: () => dispatch(hideModal()),
  updateSharedAccounts: (id, getData) => dispatch(RXSharedAccountsCreate(id, getData)),
});

export default translate()(connect(
  mapStateToProps,
  mapDispatchToProps,
)(withOverlayAction(ModalAccountuserContainer)));
