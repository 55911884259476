/*
 * Copyright (C) 2018-2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { MODAL_ACCOUNT_EDIT } from 'constants/modal';
import { SOURCE_WEB_APP } from 'constants/events';
import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Credential } from '@nettoken/models';
import { hideModal, showModal } from 'main/modal';
import withOverlayAction from 'Overlay/withAction';
import { history } from 'reducers/store';
import { sharedInvitation } from 'main/sharedAccounts';
import { updateCredential, deleteCredential, moveCredentialToGroupId } from 'main/vault/credentials';
import { getOneCredential } from 'main/vault/credentials/reduxState';
import Container from './container';


class ModalSharingByInvitation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      acceptProcessing: false,
      deleteProcessing: false,
      newCredentialId: '',
    };
    this.acceptSharingInvitation = this.acceptSharingInvitation.bind(this);
    this.declineSharingInvitation = this.declineSharingInvitation.bind(this);
    this.onClickClose = this.onClickClose.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  componentDidMount() {
    this.props.addOverlayAction('modal', this.hideModal);
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.state.acceptProcessing &&
      this.state.newCredentialId !== '' &&
      this.state.newCredentialId in nextProps.credentials &&
      nextProps.credentials[this.state.newCredentialId].invitationId == ''
    ) {
      const credential = nextProps.credentials[this.state.newCredentialId];
      this.props.showModal(MODAL_ACCOUNT_EDIT, credential);
      this.setState({
        acceptProcessing: false,
        newCredentialId: '',
      });
    }
  }

  hideModal() {
    history.replace({ hash: '', search: '' });
    this.props.hide();
  }

  onClickClose() {
    this.props.hide();
  }

  async acceptSharingInvitation() {
    let groupId;
    this.setState({ acceptProcessing: true });
    const obj = this.props.groups;
    Object.keys(obj).forEach(key => {
      if (
        obj[key].label === 'unsorted' &&
        (
          !('dashboardSpaceId' in obj[key]) ||
          (
            obj[key].dashboardSpaceId === '' ||
            obj[key].dashboardSpaceId === null
          )
        )
      ) {
        groupId = obj[key].id;
      }
    });
    const params = {
      sharedId: this.props.data.invitationId,
      credentialName: this.props.data.name,
      isAccepted: true,
      id: this.props.data.id,
      groupIdss: groupId,
    };

    try {
      const res = await this.props.sharedInvitation(params);
      const { dispatch } = this.props;
      const prevData = this.props.getCredential(this.props.data.id);
      const { id } = this.props.data;
      const { id: credentialId } = res;
      this.setState({
        newCredentialId: credentialId,
      });
      const credential = new Credential({
        ...prevData,
        accepted: true,
        shared: true,
        externalAccount: true,
        groupId,
        credentialId,
        invitationId: '',
      });
      dispatch(updateCredential({
        credential,
        id,
        pushToServer: false,
        groupId,
        credentialId,
        prevGroupId: prevData.groupId,
      }));
    }
    catch (error) {
      console.log(error, 'acceptSharingInvitation');
    }
  }

  async declineSharingInvitation() {
    this.setState({ deleteProcessing: true });
    const params = {
      sharedId: this.props.data.invitationId,
      credentialName: this.props.data.name,
      isAccepted: false,
      id: this.props.data.id,
    };

    try {
      const { id } = this.props.data;
      const res = await this.props.sharedInvitation(params);
      this.setState({ deleteProcessing: false });
      this.props.deleteCredential({
        id,
        pushToServer: false,
      });
      this.props.hide();
    }
    catch (error) {
      console.log(error, 'declineSharingInvitation');
    }
  }

  render() {
    return (
      <Container
        domain={this.props.data.domain}
        loginUrl={this.props.data.loginUrl}
        name={this.props.data.name}
        picture={this.props.data.picture}
        sharedByUserName={this.props.data.sharedByUserName}
        sharedByUserPhone={this.props.data.sharedByUserPhone}
        accepted={this.props.data.accepted}
        onClickAccepted={this.acceptSharingInvitation}
        onClickDecline={this.declineSharingInvitation}
        onClickClose={this.onClickClose}
        acceptProcessing={this.state.acceptProcessing}
        deleteProcessing={this.state.deleteProcessing}
        t={this.props.t} />
    );
  }
}

const mapStateToProps = state => ({
  credentials: state.credentials.data,
  data: state.ui.modalData || {},
  groups: state.groups.data,
  unsortedId: state.groups.unsortedGroupId,
  editAccountProccessing: state.ui.editAccountProccessing,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
  updateCredential: args => dispatch(updateCredential(args)),
  deleteCredential: args => dispatch(deleteCredential(args)),
  getCredential: id => dispatch(getOneCredential(id)),
  hide: () => dispatch(hideModal()),
  showModal: (name, data) => dispatch(showModal(name, data)),
  sharedInvitation: params => dispatch(sharedInvitation(params)),
});

export default translate()(connect(
  mapStateToProps,
  mapDispatchToProps,
)(withOverlayAction(ModalSharingByInvitation)));
