/*
 * Copyright (C) 2018-2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { f1 } from 'constants/flags';
import { MODAL_UNSAVED_CHANGES, MODAL_PASSWORD_CHECKUP } from 'constants/modal';
import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import Validator from '@nettoken/validator';
import { eventTarget, JWTDecode } from '@nettoken/helpers';
import Routes from 'config/routes';
import { getError } from 'main/error/reduxState';
import { getFlag } from 'main/flags/reduxState';
import { showModal, hideModal } from 'main/modal';
import { setConnectionLostTimeout, setTrackingPermission } from 'main/preferences';
import { goTo } from 'main/router';
import { changeUserPersonalDetails, signOutUser, syncUserPersonalDetails } from 'main/user';
import { RXUserChangesSave, RXUserChangesUndo } from 'main/user/reduxActions';
import { getUserProfile, shouldShowYellowBrickRoad } from 'main/user/reduxState';
import withOverlayAction from 'Overlay/withAction';
import Styles from 'Modal/style.css';
import classNames from 'classnames';
import {
  MODAL_ADD_ACCOUNT_NAVLIST,
  MODAL_DASHBOARDS,
  MODAL_DELETE_ACCOUNT,
  MODAL_DOWNLOAD_BACKUP_KEY,
  MODAL_DOWNLOAD_DATA,
  MODAL_EDIT_DASHBOARD,
  MODAL_MOVE_ACCOUNTS,
  MODAL_MY_ACCOUNT,
  MODAL_SESSION_SETTINGS,
  MODAL_TAKE_NOTE_PRIVATE_DASHBOARD_MOVE_ACCOUNTS,
  MODAL_TAKE_NOTE_PRIVATE_DASHBOARD_ADD_ACCOUNTS,
  MODAL_TAKE_NOTE_NO_DASHBOARD,
} from '../../constants/modal';
import union from '../../assets/images/Union.svg';

class RightContextMenu extends React.Component {
  constructor(props) {
    super(props);
    this.openTemplate = this.openTemplate.bind(this);
  }

  openTemplate(actionPopup) {
    this.props.hideMenu();
    switch (actionPopup) {
      case 'addAccount':
        if (this.props.dashboardType) {
          this.props.showModal(MODAL_TAKE_NOTE_PRIVATE_DASHBOARD_ADD_ACCOUNTS);
        }
        else {
          this.props.showModal(MODAL_ADD_ACCOUNT_NAVLIST);
        }
        break;
      case 'moveAccounts':
        if (this.props.currentDashboard == null &&
          Object.values(this.props.userDashboardsWithoutExternalDashboard).length === 0) {
          this.props.showModal(MODAL_TAKE_NOTE_NO_DASHBOARD);
        }
        else if (this.props.dashboardType) {
          this.props.showModal(MODAL_TAKE_NOTE_PRIVATE_DASHBOARD_MOVE_ACCOUNTS);
        }
        else {
          this.props.showModal(MODAL_MOVE_ACCOUNTS, {
            id: this.props.currentDashboard,
            backBtn: () => this.props.hide(),
          });
        }
        break;
      case 'myNettoken':
        this.props.showModal(MODAL_MY_ACCOUNT);
        break;
      case 'editDashboard':
        this.props.showModal(MODAL_EDIT_DASHBOARD, {
          id: this.props.currentDashboard,
          dashboardName: this.props.currentDashboardName,
        });
        break;
      default:
        this.props.showModal(MODAL_ADD_ACCOUNT_NAVLIST);
        break;
    }
  }

  render() {
    return (
      <div
        style={{
          display: this.props.show ? 'block' : 'none',
          position: 'absolute',
          height: '100%',
          width: '100%',
          top: '0',
          zIndex: '999',
        }}
      >
        <div style={{
          position: 'absolute',
          top: this.props.top,
          left: this.props.right,
        }}>
        <ul className={classNames({
          [Styles.tabList]: true,
          [Styles.tabShadows]: true,
        })}>
            {this.props.options.map(item => (
              <li
                className={Styles.rightMenuList}
                onClick={() => this.openTemplate(item.value)}
              ><img src={item.icon} style={{ width: '34px', height: '24px', paddingRight: '10px' }} />{item.label}</li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  let userDashboardsWithoutExternalDashboard = {};
  Object.keys(state.ui.dashboards).map(dashboardId => {
    if (
      (!('externalDashboard' in state.ui.dashboards[dashboardId]) || (!state.ui.dashboards[dashboardId].externalDashboard)) && (!('invitationId' in state.ui.dashboards[dashboardId]))
    ) {
      userDashboardsWithoutExternalDashboard = {
        ...userDashboardsWithoutExternalDashboard,
        [userDashboardsWithoutExternalDashboard]: { ...state.ui.dashboards[dashboardId] },
      };
    }
  });

  return {
    currentDashboard: state.ui.currentDashboard,
    dashboards: state.ui.dashboards,
    userDashboardsWithoutExternalDashboard,
    dashboardType: state.ui.currentDashboard && state.ui.dashboards[state.ui.currentDashboard] &&
    'externalDashboard' in state.ui.dashboards[state.ui.currentDashboard] ? state.ui.dashboards[state.ui.currentDashboard].externalDashboard : false,
    currentDashboardName: state.ui.currentDashboard ? state.ui.dashboards[state.ui.currentDashboard].name : '',
  };
};

const mapDispatchToProps = dispatch => ({
  changeUserPersonalDetails: e => dispatch(changeUserPersonalDetails(e)),
  getError: id => dispatch(getError(id)),
  getFlag: id => dispatch(getFlag(id)),
  getUserProfile: () => dispatch(getUserProfile()),
  goToBasics: () => dispatch(goTo(Routes.SETTINGS_BASICS)),
  goToDownload: () => dispatch(goTo(Routes.SETTINGS_DOWNLOAD_DATA)),
  goToBackup: () => dispatch(goTo(Routes.SETTINGS_BACKUP)),
  goToDashboard: () => dispatch(goTo(Routes.DASHBOARD)),
  goToDeleteAccount: () => dispatch(goTo(Routes.SETTINGS_DELETE_ACCOUNT)),
  goToImport: () => dispatch(goTo(Routes.SETTINGS_IMPORT)),
  goToMore: () => dispatch(goTo(Routes.SETTINGS_MORE)),
  goToPrivacy: () => dispatch(goTo(Routes.PRIVACY)),
  goToSession: () => dispatch(goTo(Routes.SETTINGS_SESSION)),
  pushSettingsToServer: () => dispatch(syncUserPersonalDetails()),
  RXUserChangesSave: () => dispatch(RXUserChangesSave()),
  RXUserChangesUndo: () => dispatch(RXUserChangesUndo()),
  setTimeout: timeout => dispatch(setConnectionLostTimeout(timeout)),
  setTrackingPermission: permission => dispatch(setTrackingPermission(permission)),
  showModal: (name, data) => dispatch(showModal(name, data)),
  showYellowBrickRoad: () => dispatch(shouldShowYellowBrickRoad()),
  signOut: () => dispatch(signOutUser()),
  hide: () => dispatch(hideModal()),
});

export default translate()(connect(
  mapStateToProps,
  mapDispatchToProps,
)(withOverlayAction(RightContextMenu)));
