/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { CW_DECRYPT_USING_MASTER_KEYPAIR, getWorkerPromise } from '@nettoken/crypto-worker';
import { RX_ERROR_UI } from 'main/error/actionTypes';

import {
  RX_MODAL_ANIMATION_ENTER,
  RX_MODAL_ANIMATION_LEAVE,
  RX_MODAL_DATA,
  RX_MODAL_FOCUS_POP,
  RX_MODAL_FOCUS_PUSH,
  RX_MODAL_TEMPLATE,
  RX_TOASTER_SHOW,
  RX_DASHBOARD_ADD,
  RX_DASHBOARDS_CREATE,
} from 'main/modal/actionTypes';

import { RX_MOVE_ID } from 'main/move/actionTypes';

import {
  RX_OVERLAY_ACTIONS,
  RX_OVERLAY_ANIMATION_ENTER,
  RX_OVERLAY_ANIMATION_LEAVE,
  RX_OVERLAY_RENDER_COMPONENT,
  RX_OVERLAY_RENDER_NULL,
} from 'main/overlay/actionTypes';

import {
  RX_SIDEBAR_ANIMATION_ENTER,
  RX_SIDEBAR_ANIMATION_LEAVE,
  RX_SIDEBAR_DATA,
  RX_SIDEBAR_TEMPLATE,
} from 'main/sidebar/actionTypes';

import { RX_APP_INIT } from 'main/state/actionTypes';
import Reducer from 'reducers/factory';
import {
  RX_DASHBOARDS_CREATE_BULK,
  RX_DASHBOARD_ACCEPT,
  RX_EDIT_ACCOUNT_PROCCESSING,
  RX_DASHBOARD_DECLINE,
  RX_DASHBOARD_UPDATE,
} from '../modal/actionTypes';
import {
  RX_UI_GUIDE_BACKUP,
  RX_UI_TOGGLE_VIEW,
  RX_UI_STICKY_LIST,
  RX_EDIT_API_ERROR,
  RX_CHANGE_ACTIVE_DASHBOARD,
  RX_DASHBOARD_REMOVE,
  RX_UPDATE_BACKUP_KEY_INFO,
  RX_UPDATE_DEFAULT_ACCOUNTS,
  RX_USERS_API_CALL_STATUS,
  RX_STOP_SHARING_DASHBOARD,
} from './actionTypes';

const defaultState = () => ({
  errors: [],
  focusedElements: [],
  gridView: false,
  modal: false, // Enter/leave animations.
  modalData: null, // Any data we might use in the modal view.
  modalTemplate: '', // Does component render at all?
  moveId: null, // Currently dragged element id.
  overlay: false, // Enter/leave animations.
  overlayData: [], // Stack of callbacks triggered on click.
  overlayTemplate: false, // Does component render at all?
  showYellowBrickRoad: false, // Guide the user to the backup your keys action?
  sidebar: false, // Enter/leave animations.
  sidebarData: null, // Any data we might use in the sidebar view.
  sidebarTemplate: '', // Does component render at all?
  stickyList: false,
  toaster: {
    status: 'close',
    type: 'success',
    value: 'Changes saved!',
  },
  editAccountProccessing: false,
  dashboards: {},
  hasApiErrors: false,
  currentDashboard: null,
  backupKeyDetails: {},
  defaultAccounts: [],
  dashboardCredsCount: 0,
  usersApiCallInProcess: false,
  usersApiCallFinished: false,
});

const reducer = new Reducer('ui', defaultState());

export default (state = reducer.defaultState(), action) => {
  switch (action.type) {
    case RX_APP_INIT:
      return reducer.merge(state);

    case RX_ERROR_UI:
      return {
        ...state,
        errors: action.arr,
      };

    case RX_MODAL_ANIMATION_ENTER:
    case RX_MODAL_ANIMATION_LEAVE:
      return {
        ...state,
        modal: action.type === RX_MODAL_ANIMATION_ENTER,
      };

    case RX_MODAL_DATA:
      return {
        ...state,
        modalData: action.data,
      };

    case RX_MODAL_FOCUS_POP:
      return {
        ...state,
        focusedElements: [
          ...state.focusedElements.slice(0, state.focusedElements.length - 1),
        ],
      };

    case RX_MODAL_FOCUS_PUSH:
      return {
        ...state,
        focusedElements: [
          ...state.focusedElements,
          action.node,
        ],
      };

    case RX_MODAL_TEMPLATE:
      if (action.template == '') {
        return {
          ...state,
          modalTemplate: action.template,
          modalData: null,
        };
      }
      return {
        ...state,
        modalTemplate: action.template,
      };

    case RX_MOVE_ID:
      return {
        ...state,
        moveId: action.id,
      };

    case RX_OVERLAY_RENDER_COMPONENT:
    case RX_OVERLAY_RENDER_NULL:
      return {
        ...state,
        overlayTemplate: action.type === RX_OVERLAY_RENDER_COMPONENT,
      };

    case RX_OVERLAY_ANIMATION_ENTER:
    case RX_OVERLAY_ANIMATION_LEAVE:
      return {
        ...state,
        overlay: action.type === RX_OVERLAY_ANIMATION_ENTER,
      };

    case RX_OVERLAY_ACTIONS:
      return {
        ...state,
        overlayData: action.arr,
      };

    case RX_SIDEBAR_DATA:
      return {
        ...state,
        sidebarData: action.data,
      };

    case RX_SIDEBAR_TEMPLATE:
      return {
        ...state,
        sidebarTemplate: action.template,
      };

    case RX_SIDEBAR_ANIMATION_ENTER:
    case RX_SIDEBAR_ANIMATION_LEAVE:
      return {
        ...state,
        sidebar: action.type === RX_SIDEBAR_ANIMATION_ENTER,
      };

    case RX_UI_GUIDE_BACKUP:
      return {
        ...state,
        showYellowBrickRoad: action.shouldShow,
      };

    case RX_UI_TOGGLE_VIEW: {
      const stickyList = !action.shouldShowGrid ? false : state.stickyList;
      return {
        ...state,
        stickyList,
        gridView: action.shouldShowGrid,
      };
    }

    case RX_UI_STICKY_LIST: {
      const stickyList = action.payload;
      return {
        ...state,
        stickyList,
      };
    }

    case RX_UPDATE_BACKUP_KEY_INFO: {
      return {
        ...state,
        backupKeyDetails: {
          ...action.payload,
        },
      };
    }

    case RX_TOASTER_SHOW: {
      return {
        ...state,
        toaster: action.toasterData,
      };
    }

    case RX_DASHBOARDS_CREATE: {
      let newDasboardObj = {};
      Object.values(action.dashboards).map(item => {
        newDasboardObj = {
          ...newDasboardObj,
          [item.id]: {
            ...item,
            encrypted: false,
          },
        };
      });
      return {
        ...state,
        dashboards: newDasboardObj,
      };
    }
    case RX_DASHBOARDS_CREATE_BULK: {
      let newDasboardObj = {};
      Object.values(action.dashboards).map(item => {
        newDasboardObj = {
          ...newDasboardObj,
          [item.id]: {
            ...item,
            encrypted: true,
          },
        };
      });
      let { currentDashboard } = state;
      if (!(state.currentDashboard in newDasboardObj)) {
        currentDashboard = null;
      }
      return {
        ...state,
        dashboards: newDasboardObj,
        currentDashboard,
      };
    }
    case RX_DASHBOARD_REMOVE: {
      // const newDashboards = Object.values(state.dashboards)
      //   .filter(item => item.id != action.id);
      // const newDashObj = {};
      return {
        ...state,
        // dashboards: newDashboards,
      };
    }

    case RX_DASHBOARD_ADD: {
      const newDasboardObj = action.dashboardName;
      const arrDashboards = {
        ...state.dashboards,
        [newDasboardObj.id]: {
          ...newDasboardObj,
          encrypted: false,
        },
      };
      return {
        ...state,
        dashboards: arrDashboards,
      };
    }

    case RX_CHANGE_ACTIVE_DASHBOARD: {
      return {
        ...state,
        currentDashboard: action.currentDashboard,
      };
    }

    case RX_EDIT_ACCOUNT_PROCCESSING: {
      return {
        ...state,
        editAccountProccessing: action.status,
      };
    }

    case RX_EDIT_API_ERROR: {
      state.hasApiErrors = action.status;
      return state;
    }

    case RX_EDIT_API_ERROR: {
      state.backupKeyDetails = action.pa;
      return state;
    }

    case RX_UPDATE_DEFAULT_ACCOUNTS: {
      state.defaultAccounts = action.accounts;
      return state;
    }
    case RX_DASHBOARD_ACCEPT: {
      // const newDashboards = Object.values(state.dashboards)
      //   .filter(item => item.id != action.id);
      // const newDashObj = {};
      delete state.dashboards[action.payload.id];
      state.dashboards[action.payload.dashboard.id] = {
        ...action.payload.dashboard,
      };
      return {
        ...state,
        // dashboards: newDashboards,
      };
    }

    case RX_DASHBOARD_DECLINE: {
      // const newDashboards = Object.values(state.dashboards)
      //   .filter(item => item.id != action.id);
      // const newDashObj = {};
      delete state.dashboards[action.payload.id];
      return {
        ...state,
        // dashboards: newDashboards,
      };
    }

    case RX_USERS_API_CALL_STATUS: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case RX_STOP_SHARING_DASHBOARD: {
      const { id, shared, userId } = action.payload;
      const usersShared = state.dashboards[id].usersSharedWith
        .filter(user => user.id != userId);
      const updatedDashboards = {
        ...state.dashboards,
        [id]: {
          ...state.dashboards[id],
          shared,
          usersSharedWith: usersShared,
        },
      };
      return {
        ...state,
        dashboards: updatedDashboards,
      };
    }

    case RX_DASHBOARD_UPDATE: {
      // const newDashboards = Object.values(state.dashboards)
      //   .filter(item => item.id != action.id);
      const newDashObj = { ...state.dashboards };
      newDashObj[action.id].name = action.dashboardName;
      return {
        ...state,
        dashboards: {
          ...newDashObj,
        },
      };
    }

    default:
      return state;
  }
};
