/*
 * Copyright (C) 2018-2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { PRESET_A11Y } from 'constants/presets';
import { TOGGLE_DEVELOPER_TOOLS } from 'constants/shortcuts';
import React from 'react';
import {
  Redirect,
  Route,
  Switch,
  withRouter,
} from 'react-router-dom';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { isProduction } from '@nettoken/env';
import Routes, { protectedRoutes } from 'config/routes';
import { RXErrorShow } from 'main/error/reduxActions';
import { getSafePathname } from 'main/router';
import classNames from 'classnames';

import {
  getParsedRoute,
  getRouteFromRedirectQueryStringParam,
  parseUrlQueryString,
  stringifyUrlQueryParams,
} from 'main/router';

import { getPreset } from 'main/tools/reduxState';
import { getUserProfile, isUserAuthenticated } from 'main/user/reduxState';
import Helmet from 'Helmet';
import Hints from 'Hints';
import {
  Auth,
  Cookies,
  Dashboard,
  DeveloperTools,
  ErrorView,
  FAQ,
  Homepage,
  GetPricing,
  Instructions,
  NavbarDashboard,
  PrivacyPolicy,
  TOS,
  AuthenticationSuccess,
} from 'LazyLoad';
import iconPlus from 'icons/plus.svg';
import Modal from 'Modal';
import OAuth from 'pages/oauth';
import Overlay from 'Overlay';
import ScrollToTop from 'ScrollToTop';
import Shortcut from 'Shortcut';
import Sidebar from 'Sidebar';
import GStyles from 'styles/index.css';
import { showModal } from 'main/modal';
import Dashpopup from './components/NavbarDashboard/welcomepopup';
import { RXToasterShow } from './main/modal/reduxActions';
import { MODAL_ADD_DASHBOARD, MODAL_DASHBOARDS, MODAL_SHARING_DASHBOARD_BY_INVITATION } from './constants/modal';
import { getDashboardList } from './main/modal/reduxState';
import Toaster from './components/Toaster';
import { getIconColor, getIconColorNewmethod } from './utils/misc';
import { doSearch } from './main/search';
import { RXChangeActiveDashboard } from './main/ui/reduxActions';
import HoverImg from './assets/images/threedots.png';
import RightContextMenu from './components/RightContextMenu';
import moveAccountsArrowIcon from './assets/images/movearrow.png';
import addAccountsPlusIcon from './assets/images/plus.png';
import yellowicon from './assets/images/yellow.png';
import info1 from './assets/images/info11.png';
import { HOME_DASHBOARD_TUTORIAL_STEPS_4, HOME_DASHBOARD_TUTORIAL_STEPS_5 } from './constants/ids';

class AppComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showRightMenu: false,
      clickTop: 0,
      clickRight: 0,
      options: [],
    };
    this.handleRightClick = this.handleRightClick.bind(this);
    this.handleDefaultClick = this.handleDefaultClick.bind(this);
    this.dashboardAction = this.dashboardAction.bind(this);
    this.onClickHandler = this.onClickHandler.bind(this);
  }

  componentDidCatch(error, info) {
    const { t } = this.props;
    const { Sentry } = window;

    if (Sentry) {
      const { email, location, name } = this.props.getUserProfile();
      const context = {};
      if (email) context.Email = email;
      if (location) context.Location = location;
      if (name) context.Name = name;

      // Print console message if the user decides to peek behind the scenes.
      console.log(t('error.comfortUser'));

      Sentry.setUserContext(context);
      Sentry.setExtraContext({
        build: process.env.BUILD,
        info,
      });
      Sentry.captureException(error);
    }

    const title = t('error.default.title');
    const text = t('error.default.text');
    this.props.showError(title, text);
  }

  componentDidMount() {
    document.addEventListener('contextmenu', this.handleRightClick);
    document.addEventListener('click', this.handleDefaultClick);
    if (this.props.getPreset(PRESET_A11Y)) {
      // import('styles/a11y-dev-only.css');
    }
    window.onbeforeunload = this.props.showToaster({
      status: 'close',
      type: '',
      value: '',
    });
  }

  handleRightClick(event) {
    if (
      this.props.modalOpen ||
      this.props.sidebarActive ||
      !this.props.router.location.pathname.includes('/dashboard') ||
      ('target' in event && 'className' in event.target && typeof event.target.className == 'string' && event.target.className.includes('NavbarDashboard'))
    ) {
      this.setState({
        showRightMenu: false,
      });
      return true;
    }
    event.preventDefault();
    const options = [{
      label: 'Add Accounts',
      value: 'addAccount',
      icon: addAccountsPlusIcon,
    }];
    if (Object.keys(this.props.dashboards).length > 0) {
      options.push({
        label: 'Move Accounts',
        value: 'moveAccounts',
        icon: moveAccountsArrowIcon,
      });
    }
    if (this.props.currentDashboard == null) {
      options.push({
        label: 'My Nettoken',
        value: 'myNettoken',
        icon: HoverImg,
      });
    }
    else {
      options.push({
        label: 'Edit Dashboard',
        value: 'editDashboard',
        icon: HoverImg,
      });
    }
    const scrollTop = (window.pageYOffset !== undefined) ?
      window.pageYOffset :
      (document.documentElement || document.body.parentNode || document.body).scrollTop;
    console.log('spec_demensions', event.clientX, event.clientY, scrollTop);
    this.setState({
      showRightMenu: true,
      clickRight: event.clientX + 2,
      clickTop: (event.clientY - 35) + scrollTop,
      options,
    });
    return false;
  }

  handleDefaultClick() {
    if (this.state.showRightMenu) {
      this.setState({
        showRightMenu: false,
      });
    }
  }

  onClickHandler(props) {
    this.props.showModal(MODAL_SHARING_DASHBOARD_BY_INVITATION, props);
  }

  componentWillUpdate() {
    document.removeEventListener('contextmenu', this.handleRightClick);
    document.removeEventListener('click', this.handleDefaultClick);
    document.addEventListener('contextmenu', this.handleRightClick);
    document.addEventListener('click', this.handleDefaultClick);
  }

  auth() {
    const isAuthenticated = this.props.isUserAuthenticated();

    if (Routes.AUTH === this.props.getParsedRoute() && isAuthenticated) {
      const redirect = this.props.getRouteFromRedirectQueryStringParam();
      return <Redirect to={redirect || Routes.DASHBOARD} />;
    }

    return (
      <Route
        component={Auth}
        exact
        path={Routes.AUTH} />
    );
  }

  loginRedirect() {
    return (
      <Route exact path={Routes.AUTHENTICATE}>
        <Redirect to={Routes.AUTH} />
      </Route>
    );
  }

  cookies() {
    return (
      <Route
        component={Cookies}
        exact
        path={Routes.LEGAL_COOKIES} />
    );
  }

  /**
   * Use regular expression to match paths that render the <Dashboard />
   * component to a single <Route>. This fixes an issue that used to
   * occur when multiple <Route> components were rendered. In those cases,
   * we inefficiently unounted and mounted the component even though it was
   * the same one! This led to performance and visual issues when we toggled
   * the sidebar menu, causing the route to change (and hence the component).
   */
  dashboard() {
    const { paths } = protectedRoutes;
    const isAuthenticated = this.props.isUserAuthenticated();

    if (paths.includes(this.props.getParsedRoute()) && !isAuthenticated) {
      return <Redirect to={this.getRedirectRoute(Routes.AUTH)} />;
    }

    return (
      <Route
        component={Dashboard}
        exact
        path={`(${paths.join('|')})`} />
    );
  }

  authenticatedUser() {
    const { paths } = protectedRoutes;
    const isAuthenticated = this.props.isUserAuthenticated();
    if (paths.includes(this.props.getParsedRoute()) && !isAuthenticated) {
      return <Redirect to={this.getRedirectRoute(Routes.AUTH)} />;
    }

    const { location } = this.props.router;
    if (location.search.includes('user-authenticated')) {
      return <Redirect to={Routes.SUCCESS_LOGGED_IN} />;
    }

    return (
      <Route
        component={AuthenticationSuccess}
        exact
        path={Routes.SUCCESS_LOGGED_IN} />
    );
  }

  faq() {
    return (
      <Route
        component={FAQ}
        exact
        path={Routes.FAQ} />
    );
  }

  getStarted() {
    return (
      <Route
        exact
        path={Routes.GET_STARTED}
      >
        <Redirect to={Routes.GET_STARTED_OVERVIEW} />
      </Route>
    );
  }

  getRedirectRoute(rawRoute = '') {
    let { location: nextRoute } = this.props.router;
    if (nextRoute.pathname.includes('user-authenticated')) {
      nextRoute = {
        ...nextRoute,
        pathname: '',
        search: '',
      };
      // rawRoute = '';
    }
    // Extract expired=true flag from the search query if it's there
    // as it got malformed and the authenticator would not show the notice.
    const queryParams = parseUrlQueryString(nextRoute.search);
    const redirectQueryParams = {};
    if (queryParams.expired) {
      redirectQueryParams.expired = queryParams.expired;
      delete queryParams.expired;
    }
    const search = stringifyUrlQueryParams(queryParams);
    const redirectQuery = search + nextRoute.hash;
    let redirect = nextRoute.pathname;
    if (redirect != '') {
      if (redirect.endsWith('/')) redirect = redirect.substr(0, redirect.length - 1);
      if (redirectQuery) redirect += `?${redirectQuery}`;
      if (redirect !== Routes.DASHBOARD) redirectQueryParams.redirect = redirect;
    }
    const redirectRoute = `${rawRoute}?${stringifyUrlQueryParams(redirectQueryParams)}`;
    return redirectRoute;
  }

  test(p) {
    return p;
  }

  homepage() {
    return (
      <Route
        component={Homepage}
        exact
        path={Routes.HOMEPAGE} />
    );
  }

  getpricing() {
    return (
      <Route
        component={GetPricing}
        exact
        path={Routes.GetPricing} />
    );
  }

  instructions() {
    return (
      <Route
        component={Instructions}
        exact
        path={Routes.INSTRUCTIONS}
      />
    );
  }

  oauth() {
    return (
      <Route
        component={OAuth}
        path={Routes.OAUTH} />
    );
  }

  privacyPolicy() {
    return (
      <Route
        component={PrivacyPolicy}
        exact
        path={Routes.LEGAL_PRIVACY} />
    );
  }

  switch() {
    return (
      <Switch>
        {this.homepage()}
        {this.getpricing()}
        {this.auth()}
        {this.loginRedirect()}
        {this.cookies()}
        {this.authenticatedUser()}
        {this.dashboard()}
        {this.getStarted()}
        {this.instructions()}
        {this.privacyPolicy()}
        {this.termsAndConditions()}
        {this.faq()}
        {this.oauth()}

        <Route component={ErrorView}/>
      </Switch>
    );
  }

  termsAndConditions() {
    return (
      <Route
        component={TOS}
        exact
        path={Routes.LEGAL_TOS} />
    );
  }

  showSidebar() {
    const pathname = getSafePathname(this.props.router.location);
    const render = pathname.includes(Routes.DASHBOARD);
    return render;
  }

  createDashboard() {
    this.props.showModal(MODAL_ADD_DASHBOARD, this.props.modalData);
  }

  manageDashboards() {
    this.props.showModal(MODAL_DASHBOARDS);
  }

  dashboardAction = dashboard => {
    if (dashboard.invitationId != '') {
      this.onClickHandler(dashboard);
      // this.props.pendingDashboardInvite(dashboard);
      return;
    }
    this.props.search(dashboard.id.toString(), 'dashboardSpaceId');
  }

  renderSidebar() {
    if (!this.showSidebar()) return null;
    const { name } = this.props.getUserProfile();
    const arrDashboards = {};
    if (Object.keys(this.props.dashboards).length > 0) {
      Object.keys(this.props.dashboards).map(resKey => {
        const counter = Object.keys(this.props.credentials).filter(itemKey => {
          if ('dashboardSpaceId' in this.props.credentials[itemKey]) {
            const tmpID = this.props.credentials[itemKey].dashboardSpaceId;
            const resID = this.props.dashboards[resKey].id;
            return tmpID === resID;
          }
          return false;
        }).length;
        arrDashboards[resKey] = {
          id: this.props.dashboards[resKey].id,
          name: this.props.dashboards[resKey].name,
          encrypted: this.props.dashboards[resKey].encrypted,
          count: counter,
          invitationId: 'invitationId' in this.props.dashboards[resKey] ? this.props.dashboards[resKey].invitationId : '',
          sharedByUserName: 'sharedByUser' in this.props.dashboards[resKey] ? this.props.dashboards[resKey].sharedByUser : '',
          externalDashboard: 'externalDashboard' in this.props.dashboards[resKey] ? this.props.dashboards[resKey].externalDashboard : false,
          shared: (!('externalDashboard' in this.props.dashboards[resKey])) && ('shared' in this.props.dashboards[resKey] && this.props.dashboards[resKey].shared),
          usersSharedWithLength: 'usersSharedWith' in this.props.dashboards[resKey] ? this.props.dashboards[resKey].usersSharedWith.length : '',
          // sharedByUserPhone: 'sharedByUserPhone' in this.props.dashboards[resKey] ?
          // this.props.dashboards[resKey].sharedByUserPhone : '',
        };
      });
    }
    return (
      <div className={GStyles.leftPanel}>
        <div className={GStyles.panelHeader}>
          <div
            className={classNames({
              [GStyles.parentSectionItem]: true,
              [GStyles.activeParent]: this.props.currentDashboard === '' || this.props.currentDashboard === null,
            })}
            onClick={() => this.props.search('', 'dashboardSpaceId')}
            id={HOME_DASHBOARD_TUTORIAL_STEPS_4}
            style={{
              overflow: 'initial',
            }}
          >
            <div className={`${GStyles.DashboardItems} ${GStyles.DashboardItemsActive}`} style={{ position: 'relative' }}>
              <svg width='50px' height='50px' className={`${GStyles.sectionItemActive}`}>
                <rect x='0' y='0' fill={getIconColorNewmethod(name)} height='100%' width='100%'></rect>
                <text
                  dominantBaseline='middle'
                  fill='white'
                  fontSize='10'
                  textAnchor='middle'
                  x='48%'
                  y='54%'
                >{name.slice(0, 2).toUpperCase()}</text>
              </svg>
              {this.props.pendingInvitesCount > 0 ? (
                <div
                  style={{
                    display: 'flex',
                    position: 'absolute',
                    height: '20.23px',
                    width: '20.23px',
                    background: 'yellow',
                    justifyContent: 'center',
                    borderRadius: '50%',
                    border: '1px solid black',
                    fontSize: '14px',
                    top: '0',
                    right: '-5px',
                    alignItems: 'center',
                  }}
                >{this.props.pendingInvitesCount}</div>
              ) : null }
            </div>
            <div>
              <span className={GStyles.dashboardName} style={{ fontWeight: 'bold' }}>{name}</span>
              <span className={`${GStyles.dashboardName} ${GStyles.dashboardNameSidePane}`}>{this.props.totalAccounts} {this.props.totalAccounts > 1 ? 'accounts' : 'account'}</span>
            </div>
          </div>
          <p className={GStyles.divider}></p>
          {Object.keys(arrDashboards).length > 0 ? Object.keys(arrDashboards).map((key, index) => {
            const { name: item, id: itemId, encrypted } = arrDashboards[key];
            return (
              <div
                className={classNames({
                  [GStyles.parentSectionItem]: true,
                  [GStyles.activeParent]: this.props.currentDashboard === itemId,
                })}
                style={{ overflow: 'visible' }}
                onClick={() => this.dashboardAction(arrDashboards[key])}
              >
                <div className={`${GStyles.DashboardItems} ${GStyles.hoverEfefct}`}>
                  <svg width='50px' height='50px' className={GStyles.sectionItemActive}>
                    <rect x='0' y='0' fill={encrypted ? '#dcdbde' : getIconColorNewmethod(item)} height='100%' width='100%'></rect>
                    <text
                      dominantBaseline='middle'
                      fill='white'
                      fontSize='10'
                      textAnchor='middle'
                      x='48%'
                      y='54%'
                    >{encrypted ? '' : item.slice(0, 2).toUpperCase()}</text>
                  </svg>
                  {'invitationId' in arrDashboards[key] && arrDashboards[key].invitationId != '' ? (
                    <div style={{ position: 'relative' }}>
                      <img
                        src={yellowicon}
                        style={{
                          position: 'absolute',
                          top: '-54px',
                          right: '-9px',
                          height: '28.5px',
                          width: '28.5px',
                        }}/>
                    </div>
                  ) : null}
                  {'externalDashboard' in arrDashboards[key] && arrDashboards[key].externalDashboard ? (
                    <div style={{ position: 'relative' }}>
                      <img
                        src={info1}
                        style={{
                          position: 'absolute',
                          top: '-54px',
                          right: '-9px',
                          height: '28.5px',
                          width: '28.5px',
                        }}/>
                    </div>
                  ) : null}
                  {(arrDashboards[key].invitationId == '' && arrDashboards[key].externalDashboard == '' && 'usersSharedWithLength' in arrDashboards[key] && arrDashboards[key].usersSharedWithLength > 0) ? (
                    <span className={GStyles.sharedMe} style={{
                      position: 'relative',
                      top: '-53px',
                      right: '-30px',
                    }}>
                      <svg width="28.5" height="28.5" viewBox="0 0 31 31" fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M16 26C9.92487 26 5 21.0751 5 15C5 8.92487 9.92487 4 16 4C22.0751 4 27 8.92487 27 15C27 21.0751 22.0751 26 16 26Z"
                            fill="white"/>
                          <circle cx="16" cy="15" r="10.75" stroke="#28283F"/>
                          <path d="M16.0902 9.37494L16.4743 9.05485C16.3805 8.94229 16.2421 8.87654 16.0956 8.87497C15.9491 8.8734 15.8092 8.93616
                            15.713 9.04668L16.0902 9.37494ZM15.5902 20.6249C15.5902 20.9011 15.8141 21.1249 16.0902 21.1249C16.3663 21.1249 16.5902
                            20.9011 16.5902 20.6249L15.5902 20.6249ZM11.5166 13.8681C11.3353 14.0764 11.3572 14.3922 11.5655 14.5735C11.7738 14.7548
                            12.0896 14.7329 12.2709 14.5246L11.5166 13.8681ZM19.7239 14.5165C19.9007 14.7286 20.216 14.7573 20.4281 14.5805C20.6403
                            14.4037 20.6689 14.0884 20.4922 13.8763L19.7239 14.5165ZM15.5902 9.37494L15.5902 20.6249L16.5902 20.6249L16.5902 9.37494L15.5902
                            9.37494ZM12.2709 14.5246L16.4673 9.7032L15.713 9.04668L11.5166 13.8681L12.2709 14.5246ZM20.4922 13.8763L16.4743 9.05485L15.7061
                            9.69503L19.7239 14.5165L20.4922 13.8763Z"
                            fill="#28283F"/>
                      </svg>
                    </span>
                  ) : null}
                </div>
                <div>
                  <span className={GStyles.dashboardName} style={{ fontWeight: 'bold' }}>{item}</span>
                  {'invitationId' in arrDashboards[key] && arrDashboards[key].invitationId != '' ? (
                    <span className={`${GStyles.dashboardName} ${GStyles.dashboardNameSidePane}`}>Pending Invitation</span>
                  ) :
                    <span className={`${GStyles.dashboardName} ${GStyles.dashboardNameSidePane}`}>{`${arrDashboards[key].count}`} {arrDashboards[key].count > 1 ? 'accounts' : 'account'}</span>
                  }
                </div>
              </div>
            );
          }) : null}
          {Object.keys(arrDashboards).length >= 1 ? (
            <div
              className={GStyles.parentSectionItem}
              style={{
                marginLeft: '20px',
                paddingLeft: '4px',
                borderTopLeftRadius: '35px',
                borderBottomLeftRadius: '35px',
              }}
              onClick={() => this.manageDashboards()}
            >
              <div
                className={`${GStyles.DashboardItems} ${GStyles.hoverEfefct}`}
                id={HOME_DASHBOARD_TUTORIAL_STEPS_5}
              >
                <div
                  style={{
                    borderRadius: '50%',
                    height: '50px',
                    width: '50px',
                    textAlign: 'center',
                    verticalAlign: 'middle',
                    lineHeight: '49px',
                    backgroundColor: 'white',
                  }}
                >
                  <img
                    alt='addIcon'
                    style={{
                      height: '30px',
                      width: '30px',
                    }}
                    src={HoverImg} />
                </div>
              </div>
              <span className={GStyles.dashboardName}>
              Manage Dashboards</span>
            </div>
          ) : (
            <div
              className={GStyles.parentSectionItem}
              style={{
                marginLeft: '20px',
                paddingLeft: '4px',
                borderTopLeftRadius: '35px',
                borderBottomLeftRadius: '35px',
              }}
              onClick={() => this.createDashboard()}
            >
              <div
                className={`${GStyles.DashboardItems} ${GStyles.hoverEfefct}`} onClick={() => this.createDashboard()}
                id={HOME_DASHBOARD_TUTORIAL_STEPS_5}
              >
                <div
                  style={{
                    borderRadius: '50%',
                    height: '50px',
                    width: '50px',
                    textAlign: 'center',
                    verticalAlign: 'middle',
                    lineHeight: '49px',
                    backgroundColor: 'white',
                  }}
                >
                  <img
                    alt='addIcon'
                    style={{
                      height: '15px',
                      width: '15px',
                    }}
                    src={iconPlus} />
                </div>
              </div>
              <span className={GStyles.dashboardName}>
              Add Dashboard</span>
            </div>
          )}
        </div>
      </div>
    );
  }

  render() {
    const { exists: hasError } = this.props.error;
    const isAuthenticated = this.props.isUserAuthenticated();
    return (
      <div className={this.showSidebar() ? GStyles.mainDiv : ''} style={{ minHeight: '850px', backgroundColor: '#dcdbde' }}>
        {this.renderSidebar()}
        <ScrollToTop
          className={GStyles.elRelative}
          scrollTo={(x, y) => window.scrollTo(x, y)}>
          <Shortcut name={TOGGLE_DEVELOPER_TOOLS} />
          <Helmet />
          {!isProduction && <DeveloperTools />}
          {/*
            Modal must be adjacent to Sidebar and before it. This is for
            CSS styles to get applied in the correct order.
          */}
          {/* {isAuthenticated && this.props.appsTotal < 10 ? <Dashpopup /> : null} */}
          <Sidebar />
          <NavbarDashboard />
          {hasError ? <Route component={ErrorView}/> : (<div>
            {this.switch()}
            <RightContextMenu
              show={this.state.showRightMenu}
              top={this.state.clickTop}
              right={this.state.clickRight}
              options={this.state.options}
              hideMenu={() => this.setState({ showRightMenu: false })}
            />
          </div>)}
          <Hints t={this.props.t} />
        </ScrollToTop>
        <div style={{
          position: 'fixed',
          top: '0',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          top: '2.5%',
          zIndex: '9999',
        }}>
          <Toaster />
        </div>
        <Modal />
        <Overlay />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  error: state.error,
  router: state.router,
  appsTotal: Object.keys(state.credentials.data).length,
  modalData: state.ui.modalData,
  currentDashboard: state.ui.currentDashboard,
  dashboards: state.ui.dashboards,
  credentials: state.credentials.data,
  sidebarActive: state.ui.sidebar,
  totalAccounts: Object.values(state.credentials.data)
    .filter(data => 'dashboardSpaceId' in data && data.dashboardSpaceId === null)
    .length,
  modalOpen: state.ui.modal,
  pendingInvitesCount: Object.values(state.credentials.data).filter(x => 'invitationId' in x && x.invitationId).length,
});

const mapDispatchToProps = dispatch => ({
  getParsedRoute: () => dispatch(getParsedRoute()),
  getPreset: name => dispatch(getPreset(name)),
  getRouteFromRedirectQueryStringParam: () => dispatch(getRouteFromRedirectQueryStringParam()),
  getUserProfile: () => dispatch(getUserProfile()),
  isUserAuthenticated: () => dispatch(isUserAuthenticated()),
  showError: (title, text) => dispatch(RXErrorShow(title, text)),
  showToaster: templateData => dispatch(RXToasterShow(templateData)),
  showModal: (name, data) => dispatch(showModal(name, data)),
  search: (query, specialSearch = '') => {
    if (specialSearch === 'dashboardSpaceId') {
      dispatch(RXChangeActiveDashboard(query));
    }
    return dispatch(doSearch(query, specialSearch));
  },
});

export default translate()(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppComponent)));
