/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import {
  AUTH,
  AUTHENTICATE,
  DASHBOARD,
  FAQ,
  HOMEPAGE,
  GetPricing,
  GET_STARTED,
  GET_STARTED_OVERVIEW,
  INSTRUCTIONS,
  LEGAL_CONTACT,
  LEGAL_COOKIES,
  LEGAL_PRIVACY,
  LEGAL_TOS,
  OAUTH,
  PRIVACY,
  SETTINGS,
  SETTINGS_BACKUP,
  SETTINGS_BASICS,
  SETTINGS_DOWNLOAD_DATA,
  SETTINGS_DELETE_ACCOUNT,
  SETTINGS_IMPORT,
  SETTINGS_MORE,
  SETTINGS_SESSION,
  SUCCESS_LOGGED_IN,
} from 'constants/routes';

/*
 * Convert route keys into an array of route paths.
 * This can be used to quickly check if a given path
 * belongs to an object.
 */
const attachPathsArray = obj => Object.defineProperty(obj, 'paths', {
  enumerable: false,
  value: Object.keys(obj).map(key => obj[key]),
});

/*
 * Require user to be authenticated.
 */
export const protectedRoutes = {
  DASHBOARD,
  PRIVACY,
  SETTINGS,
  SETTINGS_BACKUP,
  SETTINGS_BASICS,
  SETTINGS_DOWNLOAD_DATA,
  SETTINGS_DELETE_ACCOUNT,
  SETTINGS_IMPORT,
  SETTINGS_MORE,
  SETTINGS_SESSION,
  SUCCESS_LOGGED_IN,
};
attachPathsArray(protectedRoutes);

export const publicRoutes = {
  AUTH,
  AUTHENTICATE,
  FAQ,
  HOMEPAGE,
  GetPricing,
  GET_STARTED,
  GET_STARTED_OVERVIEW,
  INSTRUCTIONS,
  LEGAL_CONTACT,
  LEGAL_COOKIES,
  LEGAL_PRIVACY,
  LEGAL_TOS,
  OAUTH,
};
attachPathsArray(publicRoutes);

const exported = {
  ...publicRoutes,
  ...protectedRoutes,
};
attachPathsArray(exported);

export default exported;
